// import React from "react";
// import { Pagination } from "@mui/material";

// const CustomPagination = ({ page, count, onChange }) => {
//   return (
//     <div className="my-5 d-flex justify-content-center">
//       <Pagination
//         page={page}
//         count={count}
//         onChange={onChange}
//         shape="circular"
//         color="primary"
//         variant="outlined"
//         sx={{
//           "& .MuiPaginationItem-root": {
//             color: "white", // Default color for pagination numbers
//             borderColor: "#3e3f40",
//           },
//           "& .MuiPaginationItem-page.Mui-selected": {
//             color: "white", // Text color for the selected page number
//             backgroundColor: "#007BFF", // Change to blue or any other desired color
//             // borderColor: "#0C0227",
//             borderColor: "red",
//           },
//           "& .MuiPaginationItem-page:hover": {
//             backgroundColor: "#0056b3", // Hover color for pagination items
//             borderColor: "#0C0227",
//           },
//         }}
//       />
//     </div>
//   );
// };

// export default CustomPagination;




import React from "react";
import { Pagination } from "@mui/material";

const CustomPagination = ({ page, count, onChange }) => {
  return (
    <div className="my-5 d-flex justify-content-center">
      <Pagination
        page={page}
        count={count}
        onChange={onChange}
        shape="circular"
        color="primary"
        variant="outlined"
        sx={{
          "& .MuiPaginationItem-root": {
            color: "white", // Default color for pagination numbers
            borderColor: "#3e3f40",
            fontSize: "16px", // Default font size
            "@media (max-width: 576px)": {
              fontSize: "12px", // Smaller font size for small devices
            },
          },
          "& .MuiPaginationItem-page.Mui-selected": {
            color: "white", // Text color for the selected page number
            backgroundColor: "#007BFF", // Change to blue or any other desired color
            borderColor: "red", // Border color for selected page
          },
          "& .MuiPaginationItem-page:hover": {
            backgroundColor: "#0056b3", // Hover color for pagination items
            borderColor: "#0C0227", // Border color for hover state
          },
        }}
      />
    </div>
  );
};

export default CustomPagination;