import * as React from "react";
// import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./Admin.css";
import { Divider, Menu, MenuItem } from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
// import logo from './logo1.png'
import { AdminPanelSettings } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HelpIcon from "@mui/icons-material/Help";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import MessageIcon from "@mui/icons-material/Message";
import AdminDashboard from "../../Pages/Dashboard/AdminDashboard";
// import { AdminContext } from "../../context/AdminContext";
import logo from "../../Assets/Images/ds-logo.png";

const menuLinkStyles = ({ isActive }) => ({
  backgroundColor: isActive ? "#1A1C33" : "",
});
const drawerWidth = 280;

function Admin(props) {
  //   const { admin, logout } = useContext(AdminContext);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [switchAccountAnchorEl, setSwitchAccountAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentsAnchorEl, setStudentsAnchorEl] = useState(null);

  const switchAccountPopOverOpen = Boolean(switchAccountAnchorEl);
  const open = Boolean(anchorEl);
  const studentsMenuOpen = Boolean(studentsAnchorEl);

  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleClose = () => {
    setMobileOpen(false);
  };
  const handleOpenSubmenu = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setAnchorEl1(null);
  };
  const handleOpenSwitchAccountPopOver = (e) => {
    setSwitchAccountAnchorEl(e.currentTarget);
  };
  const handleCloseSwitchAccountPopOver = () => {
    setSwitchAccountAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const openStudentsMenu = (event) => {
    setStudentsAnchorEl(event.currentTarget);
  };
  const closeStudentsMenu = () => {
    setStudentsAnchorEl(null);
  };

  // const navigate = useNavigate();

  const drawer = (
    <div className="sideBar">
      <Toolbar />
      <NavLink
        className=""
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
          className="dashLogo mx-auto mb-0 pb-0"
          src={logo}
          alt="Dashboard Logo"
        />
      </NavLink>

      {/* <Divider className="mt-0 pt-0" /> */}
      <div className="menuDiv">
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="/admin/dashboard"
        >
          <DashboardIcon className="navIconAdmin" /> DASHBOARD
        </NavLink>
        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="adminUser"
        >
          <AdminPanelSettings className="navIconAdmin" /> ADMINS
        </NavLink>

        <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="users"
        >
          <SupervisedUserCircleIcon className="navIconAdmin" />
          USERS
        </NavLink>
        {/* <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="payments"
        >
          <PaymentIcon className="navIconAdmin" />
          PAYMENTS
        </NavLink> */}

        {/* <NavLink
          className="dashboardMenu"
          style={menuLinkStyles}
          onClick={handleClose}
          to="contacts"
        >
          <MessageIcon className="navIconAdmin" />
          CONTACT US
        </NavLink> */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          <div
            style={{ backgroundColor: "" }}
            className="text-light text-uppercase"
          ></div>
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={studentsAnchorEl}
          open={studentsMenuOpen}
          onClose={closeStudentsMenu}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          <div
            style={{ backgroundColor: "" }}
            className="text-light text-uppercase"
          >
            <div className="pt-5">
              <MenuItem
                onClick={() => {
                  closeStudentsMenu();
                  handleClose();
                  navigate("/admin/users");
                }}
                className="text-uppercase "
              >
                All Users
              </MenuItem>
            </div>
          </div>
        </Menu>
        <button
          onClick={() => {
            localStorage.removeItem('AiAdmin');
            navigate('/');
          }}
          className="LogoutButton"
        >
          Logout
        </button>
      </div>
    </div>
  );

  //   const container = window !== undefined ? () => window().document.body : undefined;

  const menuToggle = () => {
    const toggleMenu = document.querySelector(".adminProfile");
    toggleMenu.classList.toggle("active");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: { sm: `${drawerWidth}px` },
          paddingRight: "0px !important",
        }}
      >
        <Toolbar className="dashboardNav">
          <IconButton
            color="inherit"
            className="ms-3"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <FormatAlignLeftIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="dashboardTopBar"
          >
            <h4 className="dashboardTitlehidden">Dashboard</h4>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "rgb(86, 54, 134) !important",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "rgb(86, 54, 134) !important",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {location.pathname === "/admin" && <AdminDashboard />}
        <Outlet />
        {/* <p className="text-white footer-part">Hello</p> */}
      </Box>
      {/* <div className="text-white footer-part">
        Hello Developers
      </div> */}
      <div className="footer-part bg-dark">
        Copyright © {new Date().getFullYear()} - DS Legends Pte. Ltd.
        
      </div>
    </Box>
  );
}

export default Admin;













