import { Box, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

function DashboardCard({ path, title, count, color }) {
  const navigate = useNavigate();
  const handleClickNavigate = (path) => {
    navigate(path);
  };
  return (
    <div onClick={() => handleClickNavigate(path)}>
      <Card
        variant="outlined"
        className="cardDash text-light hover:opacity-100 opacity-95 cursor-pointer"
        style={{ backgroundColor: color }}
      >
        <CardContent className="flex items-center justify-between">
          <Box className="text-white">
            <p className="uppercase">{title}</p>
            <h2 className="mt-3 text-start text-white font-bold text-3xl">
              {count}
            </h2>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default DashboardCard;
