import React, { useContext, useState } from "react";
import "./Navbar.css";
import dslLogo from "../../Assets/Images/dsl-logo.png";
import { Link, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import closeIcon from "../../Assets/Images/close-icon.png";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { Dropdown } from "antd";

const Navbar = () => {
  const [showOffCanvasOpen, setOffCanvasOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdown1, setOpenDropdown1] = useState(false);
  const [openDropdownAPI, setOpenDropdownAPI] = useState(false);
  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "DASHBOARD",
    },
    // {
    //   key: "2",
    //   label: "PURCHASE",
    // },
    {
      key: "3",
      label: "PURCHASE HISTORY",
    },
    {
      key: "4",
      label: "PAYMENT METHODS",
    },
    {
      key: "5",
      label: "TRANSACTIONS",
    },
    {
      key: "6",
      label: "EARNINGS",
    },
    {
      key: "16",
      label: "API SETTINGS",
    },
    {
      key: "61",
      label: "SOCIAL MEDIA LOGINS",
    },
  ];

  // const item = [
  //   {
  //     key: "1",
  //     label: "About Us",
  //   },
  //   {
  //     key: "2",
  //     label: "Pricing",
  //   },
  //   {
  //     key: "3",
  //     label: "Generative AI",
  //   },
  //   {
  //     key: "4",
  //     label: "Contact Us",
  //   },
  // ];

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      navigate("/dashboard");
    } 
    else if (e.key === "2") {
      navigate("/purchase");
    } else if (e.key === "3") {
      navigate("/purchase-history");
    } else if (e.key === "4") {
      navigate("/payment-methods");
    } else if (e.key === "5") {
      navigate("/transactions");
    } else if (e.key === "6") {
      navigate("/earnings");
    }
    else if (e.key === "16") {
      navigate("/api-settings");
    }
    else if (e.key === "61") {
      navigate("/social-media-logins");
    }
  };

  const handleMenu = (e) => {
    if (e.key === "7") {
      navigate("/about-us");
    } else if (e.key === "8") {
      navigate("/pricing");
    } else if (e.key === "9") {
      navigate("/artificial-intelligence");
    } else if (e.key === "10") {
      navigate("/generative-ai");
    } else if (e.key === "11") {
      // navigate("/contact-us");
      navigate("/social-media-marketing");
    } else if (e.key === "12") {
      navigate("/api");
    } else if (e.key === "13") {
      navigate("/do-it-yourself");
    } else if (e.key === "14") {
      navigate("/let-us-do-it-for-you");
    }
    else if (e.key === "15") {
      navigate("/how-it-works");
    }
    else if (e.key === "17") {
      navigate("/contents");
    }


    else if (e.key === "21") {
      navigate(`${AIuser ? "/consultant-ai" : "/sign-in"}`);
    }
    else if (e.key === "22") {
      navigate(`${AIuser ? "/text-ai" : "/sign-in"}`);
    }
    else if (e.key === "23") {
      navigate(`${AIuser ? "/image-ai" : "/sign-in"}`);
    }
    else if (e.key === "24") {
      navigate(`${AIuser ? "/post-ai" : "/sign-in"}`);
    }
    else if (e.key === "25") {
      navigate(`${AIuser ? "/text-video-ai" : "/sign-in"}`);
    }
    else if (e.key === "26") {
      navigate(`${AIuser ? "/news-video-ai" : "/sign-in"}`);
    }
    else if (e.key === "27") {
      navigate(`${AIuser ? "/comic-video-ai" : "/sign-in"}`);
    }
    else if (e.key === "28") {
      navigate("/ai-social-media-content-generator");
    }
  };

  const handleOpenDropdown = (e) => {
    setOpenDropdown(!openDropdown);
  };
  const handleOpenDropdown1 = (e) => {
    setOpenDropdown1(!openDropdown1);
  };
  const handleOpenDropdownAPI = (e) => {
    setOpenDropdownAPI(!openDropdownAPI);
  }

  const mobileDropdownPaths = [
    { path: `${AIuser ? "/dashboard" : "/sign-in"}`, label: "DASHBOARD" },
    // { path: `${AIuser ? "/purchase" : "/sign-in"}`, label: "PURCHASE" },
    {
      path: `${AIuser ? "/purchase-history" : "/sign-in"}`,
      label: "PURCHASE HISTORY",
    },
    {
      path: `${AIuser ? "/payment-methods" : "/sign-in"}`,
      label: "PAYMENT METHODS",
    },
    { path: `${AIuser ? "/transactions" : "/sign-in"}`, label: "TRANSACTIONS" },
    { path: `${AIuser ? "/earnings" : "/sign-in"}`, label: "EARNINGS" },
    { path: `${AIuser ? "/api-settings" : "/sign-in"}`, label: "API SETTINGS" },
    { path: `${AIuser ? "/social-media-logins" : "/sign-in"}`, label: "SOCIAL MEDIA LOGINS" },
  ];

  const mobileDropdownPaths2 = [
    { path: "/about-us", label: "About Us" },
    { path: "/pricing", label: "Pricing" },
    
    // { path: "/contact-us", label: "Contact Us" },
    // { path: "/api", label: "API" },

    // hide To DO 
    // { path: "/artificial-intelligence", label: "Artificial Intelligence" },
    // {
    //   path: "/generative-ai",
    //   label: "Generative AI",
    // },
    // { path: "/social-media-marketing", label: "Social Media Marketing" },
    { path: "/how-it-works", label: "How it works" },
    { path: "/contents", label: "CONTENTS" },
    { path: "/do-it-yourself", label: "Do it yourself" },
    { path: "/let-us-do-it-for-you", label: "Let us do it for you" },
    { path: "/ai-social-media-content-generator", label: "AI SOCIAL MEDIA CONTENT GENERATOR" },
  ];


  const mobileDropdownPaths3 = [
    { path: `${AIuser ? "/consultant-ai" : "/sign-in"}`, label: "CONSULTANT AI" },
    { path: `${AIuser ? "/text-ai" : "/sign-in"}`, label: "TEXT AI" },
    {
      path: `${AIuser ? "/image-ai" : "/sign-in"}`,
      label: "IMAGE AI",
    },
    {
      path: `${AIuser ? "/post-ai" : "/sign-in"}`,
      label: "POST AI",
    },
    { path: `${AIuser ? "/text-video-ai" : "/sign-in"}`, label: "TEXT VIDEO AI" },
    { path: `${AIuser ? "/comic-video-ai" : "/sign-in"}`, label: "COMIC VIDEO AI" },
    { path: `${AIuser ? "/news-video-ai" : "/sign-in"}`, label: "NEWS VIDEO AI" },
    
    // { path: `${AIuser ? "/ai-social-media-content-generator" : "/sign-in"}`, label: "AI SOCIAL MEDIA CONTENT GENERATOR" },
  ];
  return (
    <div className="navbar-container d-flex align-items-center">
      <div className="navbar-main container">
        <div className="nav-left d-flex">
          <Link to={"/"}>
            <img className="dsl-logo" src={dslLogo} alt="image" />
          </Link>
          <h1
            className="text-white my-4 custom-width-title-content ms-3 d-lg-block d-md-block d-none"
            style={{ fontSize: "12px" }}
          >
            {/* A complete social media marketing manager. */}
            An AI platform that helps you create content for your social media channels.
          </h1>
        </div>
        <div className="d-lg-none d-md-none d-block">
          <h1
            className="text-white my-4 custom-width-title-content"
            style={{ fontSize: "12px" }}
          >
            {/* A complete social media marketing manager. */}
            An AI platform that helps you create content for your social media channels.
          </h1>
        </div>
        <div className="nav-right">
          <div className="nav-menus-in-large d-none d-lg-flex">
            
            <Dropdown
              menu={{
                items: [
                  { key: "21", label: "CONSULTANT AI" },
                  { key: "22", label: "TEXT AI" },
                  { key: "23", label: "IMAGE AI" },
                  { key: "24", label: "POST AI" },
                  { key: "25", label: "TEXT VIDEO AI" },
                  { key: "27", label: "COMIC VIDEO AI" },
                  { key: "26", label: "NEWS VIDEO AI" },
                  
                  // { key: "28", label: "AI SOCIAL MEDIA CONTENT GENERATOR" },
                ],
                selectable: true,
                defaultSelectedKeys: ["7"], // Set default selected key as needed
                onClick: handleMenu,
              }}
              trigger={["click"]}
              arrow={{
                pointAtCenter: true,
              }}
              className="text-uppercase"
            >
              <li>
                <a
                  className="text-light text-uppercase"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  AI
                </a>
              </li>
            </Dropdown>
            
            <Dropdown
              menu={{
                items: [
                  { key: "7", label: "ABOUT US" },
                  { key: "8", label: "PRICING" },
                  // Hide To Do 
                  // { key: "9", label: "ARTIFICIAL INTELLIGENCE" },
                  // { key: "10", label: "GENERATIVE AI" },
                  // { key: "11", label: "SOCIAL MEDIA MARKETING" },
                  // { key: "12", label: "API" },
                  { key: "15", label: "HOW IT WORKS" },
                  { key: "17", label: "CONTENTS" },
                  { key: "13", label: "DO IT YOURSELF", style: { backgroundColor: "#3080AC", color: "white" } }, //#011225
                  { key: "14", label: "LET US DO IT FOR YOU", style: { backgroundColor: "#6960ED", color: "white", marginTop: "2px" } },
                  { key: "28", label: "AI SOCIAL MEDIA CONTENT GENERATOR" }, 
                ],
                selectable: true,
                // items: menuItems,
                defaultSelectedKeys: ["7"], // Set default selected key as needed
                onClick: handleMenu,
                
              }}
              trigger={["click"]}
              arrow={{
                pointAtCenter: true,
              }}
              className="text-uppercase"
            >
              <li>
                <a
                   className="text-light text-uppercase"
                   style={{
                     cursor: "pointer",
                   }}
                >
                  MENU
                </a>
              </li>
            </Dropdown>

            

            {/* <li className='text-white d-flex gap-2 items-center justify-content-center '>
          PRICING<Link to={`${AIuser ? '/pricing' : '/sign-in'}`}><MdInfoOutline /></Link>
            </li> */}
            {/* <li>
              <Link to={`${AIuser ? "/consultant-ai" : "/sign-in"}`}>
                CONSULTANT AI
              </Link>
            </li>
            <li>
              <Link to={`${AIuser ? "/text-ai" : "/sign-in"}`}>TEXT AI</Link>
            </li>

            <li>
              <Link to={`${AIuser ? "/image-ai" : "/sign-in"}`}>IMAGE AI</Link>
            </li>
            <li>
              <Link to={`${AIuser ? "/post-ai" : "/sign-in"}`}>POST AI</Link>
            </li>
            <li>
              <Link to={`${AIuser ? "/text-video-ai" : "/sign-in"}`}>
                TEXT VIDEO AI
              </Link>
            </li>

            <li>
              <Link to={`${AIuser ? "/news-video-ai" : "/sign-in"}`}>
                NEWS VIDEO AI
              </Link>
            </li>
            <li>
              <Link to={`${AIuser ? "/comic-video-ai" : "/sign-in"}`}>
                COMIC VIDEO AI
              </Link>
            </li> */}
            {AIuser?._id && (
              <Dropdown
                menu={{
                  items,
                  selectable: true,
                  defaultSelectedKeys: ["1"],
                  onClick: handleMenuClick,
                }}
                trigger={["click"]}
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <li>
                  <a
                    className="text-light"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    ACCOUNT
                  </a>
                </li>
              </Dropdown>
            )}

            <li>
              {AIuser?._id ? (
                <button
                  onClick={() => {
                    AiLogOut();
                    navigate("/");
                  }}
                  className="login-button"
                >
                  Sign out
                </button>
              ) : (
                <button
                  onClick={() => navigate("/sign-in")}
                  className="login-button"
                >
                  Sign in
                </button>
              )}
            </li>
          </div>
          <div className="ham-burger-container d-lg-none">
            <button
              onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}
              className="ham-burger-button"
            >
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>

        {/* OFF CANVAS */}
        <Offcanvas
          show={showOffCanvasOpen}
          onHide={() => setOffCanvasOpen(!showOffCanvasOpen)}
          placement="end"
        >
          <div className="close-button-container">
            <button
              onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}
              className="offcanvas-close-button"
            >
              <img src={closeIcon} alt="image" />
            </button>
          </div>
          <Offcanvas.Body>
            <div
              // onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}
              className="off-canvas-menus-container"
            >
               {/* API Dropdown  */}
               <li
                menu={{
                  items: [
                    { key: "21", label: "CONSULTANT AI" },
                    { key: "22", label: "TEXT AI" },
                    { key: "23", label: "IMAGE AI" },
                    { key: "24", label: "POST AI" },
                    { key: "25", label: "TEXT VIDEO AI" },
                    { key: "26", label: "NEWS VIDEO AI" },
                    { key: "27", label: "COMIC VIDEO AI" },
                    // { key: "28", label: "AI SOCIAL MEDIA CONTENT GENERATOR" },
                  ],
                  selectable: true,
                  defaultSelectedKeys: ["7"], // Set default selected key as needed
                  // onClick: handleMenu,
                }}
                trigger={["click"]}
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <li onClick={handleOpenDropdownAPI}>
                  <a
                    className="text-light text-uppercase"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    AI{" "}
                    {openDropdown ? (
                      <i class="fa-solid fa-caret-up"></i>
                    ) : (
                      <i class="fa-solid fa-caret-down"></i>
                    )}
                  </a>
                </li>
              </li>


              {/* API drop down  */}
              {openDropdownAPI && (
                <>
                  {mobileDropdownPaths3?.map(({ path, label }) => (
                    <li
                      onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}
                      key={label}
                      className="text-primary"
                    >
                      <Link className="text-warning text-uppercase" to={path}>
                        {label}
                      </Link>
                    </li>
                  ))}
                </>
              )}

              
               <hr
                style={{
                  color: "white",
                  backgroundColor: "white",
                  width: "50%",
                  height: "2px",
                }}
              />
              <li
                menu={{
                  items: [
                    { key: "7", label: "About Us" },
                    { key: "8", label: "Pricing" },
                    { key: "9", label: "Artificial Intelligence" },
                    { key: "10", label: "Generative AI" },
                    { key: "11", label: "API" },
                    { key: "12", label: "Do it yourself" },
                    { key: "13", label: "Let us do it for you" }, 
                    { key: "15", label: "HOW IT WORKS" },
                    { key: "17", label: "CONTENTS" },      
                    { key: "28", label: "AI SOCIAL MEDIA CONTENT GENERATOR" },
                  ],
                  selectable: true,
                  defaultSelectedKeys: ["7"], // Set default selected key as needed
                  // onClick: handleMenu,
                }}
                trigger={["click"]}
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <li onClick={handleOpenDropdown}>
                  <a
                    className="text-light text-uppercase"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    MENU{" "}
                    {openDropdown ? (
                      <i class="fa-solid fa-caret-up"></i>
                    ) : (
                      <i class="fa-solid fa-caret-down"></i>
                    )}
                  </a>
                </li>
              </li>

              {openDropdown && (
                <>
                  {mobileDropdownPaths2?.map(({ path, label }) => (
                    <li
                      onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}
                      key={label}
                      className="text-primary"
                    >
                      {/* <Link className={`text-uppercase ${label === 'DO IT YOURSELF' || label === 'Let us do it for you' ? 'text-primary' : 'text-warning'}`} to={path}>
                        {label}
                      </Link> */}
                      {/* <Link className={`text-uppercase ${label === 'Do it yourself' ? 'text-blue' : 'text-warning'}`} to={path}>
                        {label}
                      </Link> */}
                      <Link
                        className="text-uppercase"
                        // style={{ color: label === 'DO IT YOURSELF' || label === 'Let us do it for you' ? '#A9D1FF' : '#FFD700' }}
                        style={{ 
                          //#E76F51
                          color: label === 'Do it yourself' ? '#26e810' :
                                 label === 'Let us do it for you' ? '#26e810' :
                                 '#FFD700'
                        }}
                        to={path}
                      >
                        {label}
                      </Link>

                    </li>
                  ))}
                </>
              )}



              
              {/* <li
              className='text-white d-flex gap-2 items-center '
              onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
              PRICING<Link to={`${AIuser ? '/pricing' : '/sign-in'}`}><MdInfoOutline /></Link>
              </li> */}
              
              {/* <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/consultant-ai" : "/sign-in"}`}>
                  CONSULTANT AI
                </Link>
              </li>
              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/text-ai" : "/sign-in"}`}>TEXT AI</Link>
              </li>
              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/image-ai" : "/sign-in"}`}>
                  IMAGE AI
                </Link>
              </li>
              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/post-ai" : "/sign-in"}`}>POST AI</Link>
              </li>
              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/text-video-ai" : "/sign-in"}`}>
                  TEXT VIDEO AI
                </Link>
              </li>

              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/news-video-ai" : "/sign-in"}`}>
                  NEWS VIDEO AI
                </Link>
              </li>
              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                <Link to={`${AIuser ? "/comic-video-ai" : "/sign-in"}`}>
                  COMIC VIDEO AI
                </Link>
              </li> */}

              <hr
                style={{
                  color: "white",
                  backgroundColor: "white",
                  width: "50%",
                  height: "2px",
                }}
              />
              {AIuser?._id && (
                <li onClick={handleOpenDropdown1}>
                  <a
                    className="text-light"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    ACCOUNT{" "}
                    {openDropdown ? (
                      <i class="fa-solid fa-caret-up"></i>
                    ) : (
                      <i class="fa-solid fa-caret-down"></i>
                    )}
                  </a>
                </li>
              )}
              {openDropdown1 && (
                <>
                  {mobileDropdownPaths?.map(({ path, label }) => (
                    <li
                      onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}
                      key={label}
                      className="text-primary"
                    >
                      <Link className="text-warning text-uppercase" to={path}>
                        {label}
                      </Link>
                    </li>
                  ))}
                </>
              )}

              <li onClick={() => setOffCanvasOpen(!showOffCanvasOpen)}>
                {AIuser?._id ? (
                  <button
                    onClick={() => {
                      AiLogOut();
                      navigate("/");
                    }}
                    className="login-button"
                  >
                    Sign out
                  </button>
                ) : (
                  <button
                    onClick={() => navigate("/sign-in")}
                    className="login-button"
                  >
                    Sign in
                  </button>
                )}
              </li>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

export default Navbar;
