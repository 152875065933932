// import React, { useEffect, useState } from "react";
// import FacebookLogin from "react-facebook-login";

// const FacebookLoginComponent = () => {
//   const [accessToken, setAccessToken] = useState(null);
//   const [pages, setPages] = useState([]);


//   // Handle response from Facebook login
//   const responseFacebook = (response) => {
//     console.log(response);
//     if (response.accessToken) {
//       setAccessToken(response.accessToken);
//       fetchPages(response.accessToken);
//     }
//   };

//   // Fetch pages the user manages
//   const fetchPages = (token) => {
//     fetch(`https://graph.facebook.com/v17.0/me/accounts?access_token=${token}`)
//       .then((res) => res.json())
//       .then((data) => {
//         if (data && data.data) {
//           setPages(data.data);
//         }
//       })
//       .catch((err) => console.error("Error fetching pages:", err));
//   };
 
//   const responseFacebookkk = (response) => {
//     console.log(response);
//   }

//   // Post to selected page
//   const postToPage = (pageId, pageAccessToken) => {
//     const message = "Hello from the frontend!"; // Customize your message

//     fetch(`https://graph.facebook.com/v17.0/${pageId}/feed`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         message: message,
//         access_token: pageAccessToken,
//       }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         console.log("Post successful:", data);
//         alert("Post successful!");
//       })
//       .catch((err) => {
//         console.error("Error posting to page:", err);
//         alert("Error posting to page");
//       });
//   };

//   return (
//     <div className style={{ marginBottom: "200px", marginTop: "200px" }}>
//       {!accessToken ? (
//         <FacebookLogin
//           appId="3757864734451749" // Replace with your Facebook App ID
//           autoLoad={true}
//           fields="name,email,picture"
//           scope="public_profile,email,pages_manage_posts,pages_read_engagement"
//           callback={responseFacebook}
//         />
//       ) : (
//         <div>
//           <h3>Select a page to post on:</h3>
//           {pages.map((page) => (
//             <div key={page.id}>
//               <p>Page: {page.name}</p>
//               <button onClick={() => postToPage(page.id, page.access_token)}>
//                 Post to {page.name}
//               </button>
//             </div>
//           ))}
//         </div>
//       )}

//       <FacebookLogin
//         appId="3757864734451749"
//         autoLoad={true}
//         fields="name,email,picture"
//         scope="public_profile,email,pages_manage_posts,pages_read_engagement"
//         callback={responseFacebookkk}
//       />
//     </div>
//   );
// };

// export default FacebookLoginComponent;



import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";

const FacebookLoginComponent = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [pages, setPages] = useState([]);

  // Handle response from Facebook login
  const responseFacebook = (response) => {
    console.log(response);
    if (response.accessToken) {
      setAccessToken(response.accessToken);
      fetchPages(response.accessToken); // Fetch the pages once we have an access token
    }
  };

  // Fetch pages the user manages
  const fetchPages = (token) => {
    fetch(`https://graph.facebook.com/v17.0/me/accounts?access_token=${token}`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.data) {
          setPages(data.data); // Set the pages in the state
        }
      })
      .catch((err) => console.error("Error fetching pages:", err));
  };

  // Post to the selected page
  const postToPage = (pageId, pageAccessToken) => {
    const message = "Hello from the frontend!"; // Customize your message

    fetch(`https://graph.facebook.com/v17.0/${pageId}/feed`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
        access_token: pageAccessToken, // Use the page's access token
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Post successful:", data);
        alert("Post successful!");
      })
      .catch((err) => {
        console.error("Error posting to page:", err);
        alert("Error posting to page");
      });
  };


  console.log('Pagessss =====>>>>', pages);
  console.log('AccessTokensss =====>>>>', accessToken);

  return (
    <div className="container" style={{ marginBottom: "200px", marginTop: "200px" }}>
      {!accessToken ? (
        <FacebookLogin
          appId="3757864734451749" // Replace with your Facebook App ID
          autoLoad={false} // AutoLoad can be false for better control
          fields="name,email,picture"
          scope="public_profile,email,pages_manage_posts,pages_read_engagement"
          callback={responseFacebook} // Callback function to handle login response
        />
      ) : (
        <div>
          <h3>Select a page to post on:</h3>
          {pages.length > 0 ? (
            pages.map((page) => (
              <div key={page.id}>
                <p>Page: {page.name}</p>
                <button onClick={() => postToPage(page.id, page.access_token)}>
                  Post to {page.name}
                </button>
              </div>
            ))
          ) : (
            <p>No pages available or permission not granted.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FacebookLoginComponent;
