import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BiLockOpen } from "react-icons/bi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { GrMail } from "react-icons/gr";
import { MdAccountCircle, MdOutlineAlternateEmail } from "react-icons/md";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from "sweetalert2";
import "./DashboardAdminEditProfile.css";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

const DashboardAdminEditProfile = () => {
  const { id } = useParams();
  const [onLoading, setonLoading] = useState(false);
  const navigate = useNavigate();

  const [valueProfilePhn, setValueProfilePhn] = useState();
  const [visibleCPassword, setVisibleCPassword] = useState(false);
  const [visibleEnPassword, setVisibleEnPassword] = useState(false);
  const [visibleCnPassword, setVisibleCnPassword] = useState(false);
  const [singleAdmin, setSingleAdmin] = useState({});
  const [currentPasswordPro, setCurrentPasswordPro] = useState("");

  const {AdminToken, getAllAdmin, getCurentAdmin} = useContext(AdminAuthContext);


  useEffect(() => {
    if (id) {
      axios
        .get(`https://backend.whalesai.com/api/v1/admin/${id}`)
        .then((res) => {
          setSingleAdmin(res.data.admin);
          setValueProfilePhn(res.data.admin.phone);
          setCurrentPasswordPro(res.data);
        });
    }
  }, [id]);
   


  console.log( 'From Edit page  ======>>>>> ' ,singleAdmin, currentPasswordPro);

  // const subProfile = async (event) => {
  //   event.preventDefault();
  
  //   // Collect the form data
  //   const name = event.target.name.value;
  //   const username = event.target.username.value;
  //   const email = event.target.email.value;
  //   const phone = valueProfilePhn;
  //   const currentPass = event.target.currentPassword.value;
  //   const password = event.target.password.value;
  //   const cPassword = event.target.cPassword.value;
  //   const avatar = event.target.avatar.files[0];
  
  //   const editedData = {
  //     name: name,
  //     username: username,
  //     email: email,
  //     phone: phone,
  //     image: avatar,
  //   };

  //   if (password || cPassword) {
  //     await changePassword(currentPass, password, cPassword);
  //   }
    
  //   console.log('editable data =====>>>>>', editedData);
  
  //   // Set up the PUT request with a Bearer token
  //   try {
  //     const formData = new FormData();
  //     formData.append('name', name);
  //     formData.append('username', username);
  //     formData.append('email', email);
  //     formData.append('phone', phone);
  //     formData.append('image', avatar); 
  
  //     const response = await fetch(`https://backend.whalesai.com/api/v1/admin/update/${id}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Authorization': `Bearer ${AdminToken}`,
  //       },
  //       body: formData,
  //     });
  
  //     const result = await response.json();
  
  //     if (response.ok) {
  //       getAllAdmin();
  //       getCurentAdmin();
  //       Swal.fire({
  //         text: "Profile has been successfully updated.",
  //         icon: "success",
  //         confirmButtonText: "Yes",
  //         customClass: "modal_class_success",
          
  //       })
  //     } else {
  //       Swal.fire({
  //         text: "Failed to update profile.",
  //         icon: "error",
  //         confirmButtonText: "Yes",
  //         customClass: "modal_class_success",
          
  //       })
  //     }
  //   } catch (error) {
  //     console.error('Error updating profile:', error);
  //     Swal.fire({
  //       text: "Something went wrong. Please try again.",
  //       icon: "error",
  //       confirmButtonText: "Yes",
  //       customClass: "modal_class_success",
  //       // background: "#011225",
  //     })
  //   }
  // };
  // const changePassword = async (currentPass, newPass, confirmPass) => {
  //   try {
  //     if (newPass !== confirmPass) {
  //       throw new Error("Confirm Password does not match!");
  //     }
  //     const formData = {
  //       currentPassword: currentPass,
  //       newPassword: newPass
  //     }
  
  //     const response = await fetch(`https://backend.whalesai.com/api/v1/admin/update/password/${id}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Authorization': `Bearer ${AdminToken}`,
  //       },
  //       body: formData,
  //     });
  
  //     const result = await response.json();
  
  //     if (response.ok) {
  //       Swal.fire({
  //         text: "Password has been successfully changed.",
  //         icon: "success",
  //         confirmButtonText: "Yes",
  //         customClass: "modal_class_success",
  //       });
  //     } else {
  //       Swal.fire({
  //         text: result.message || "Failed to change password.",
  //         icon: "error",
  //         confirmButtonText: "Yes",
  //         customClass: "modal_class_success",
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Error changing password:', error);
  //     Swal.fire({
  //       text: "Something went wrong with changing password. Please try again.",
  //       icon: "error",
  //       confirmButtonText: "Yes",
  //       customClass: "modal_class_success",
  //     });
  //   }
  // };





  const subProfile = async (event) => {
    event.preventDefault();
  
    // Collect the form data
    const name = event.target.name.value;
    const username = event.target.username.value;
    const email = event.target.email.value;
    const phone = valueProfilePhn;
    const currentPass = event.target.currentPassword.value;
    const password = event.target.password.value;
    const cPassword = event.target.cPassword.value;
    const avatar = event.target.avatar.files[0];
  
    const editedData = {
      name,
      username,
      email,
      phone,
      image: avatar,
    };
  
    try {
      // If password or confirm password is provided, handle password change first
      if (password || cPassword) {
        await handlePasswordChange(currentPass, password, cPassword);
      }
  
      // Update profile regardless of password change
      await updateProfile(editedData);
  
    } catch (error) {
      console.error('Error processing request:', error);
      Swal.fire({
        text: 'Confirm Password does not match!',
        icon: "error",
        confirmButtonText: "Yes",
        customClass: "modal_class_error",
        background: '#011225',
        color: "#fff"
      });
    }
  };
  
  // Function to handle password change
  const handlePasswordChange = async (currentPass, newPass, confirmPass) => {
    if (newPass && newPass !== confirmPass) {
      Swal.fire({
        text: "Confirm Password does not match!",
        icon: "error",
        confirmButtonText: "Yes",
        customClass: "modal_class_error",
         background: '#011225',
        color: "#fff"
      });
      throw new Error("Passwords do not match");
    }
  
    try {
      const response = await fetch(`https://backend.whalesai.com/api/v1/admin/update/password/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${AdminToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentPassword: currentPass,
          newPassword: newPass,
        }),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error(result.message || "Failed to change password.");
      }
  
      // Swal.fire({
      //   text: "Password has been successfully changed.",
      //   icon: "success",
      //   confirmButtonText: "Yes",
      //   customClass: "modal_class_success",
      //    background: '#011225'
      // });
    } catch (error) {
      console.error('Error changing password:', error);
      Swal.fire({
        text: error.message || "Something went wrong with changing password. Please try again.",
        icon: "error",
        confirmButtonText: "Yes",
        customClass: "modal_class_error",
         background: '#011225',
        color: "#fff"
      });
      throw error;  // Re-throw error to halt profile update if password change fails
    }
  };
  
  // Function to update profile
  const updateProfile = async (data) => {
    try {
      const formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
  
      const response = await fetch(`https://backend.whalesai.com/api/v1/admin/update/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${AdminToken}`,
        },
        body: formData,
      });
  
      const result = await response.json();
  
      if (response.ok) {
        getAllAdmin();
        getCurentAdmin();
        Swal.fire({
          text: "Admin profile has been successfully updated.",
          icon: "success",
          confirmButtonText: "Yes",
          customClass: "modal_class_success",
           background: '#011225',
        color: "#fff"
        });
        navigate('/admin/adminUser');
      } else {
        Swal.fire({
          text: "Failed to update profile.",
          icon: "error",
          confirmButtonText: "Yes",
          customClass: "modal_class_error",
           background: '#011225',
        color: "#fff"
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      Swal.fire({
        text: "Something went wrong. Please try again.",
        icon: "error",
        confirmButtonText: "Yes",
        customClass: "modal_class_error",
         background: '#011225',
        color: "#fff"
      });
    }
  };
  
  return (
    <>
      <div className="handleEditAdminHeight">
        <h5
          className={`text-start fw-bold text-white font-bold`}
          style={{ textTransform: "uppercase", fontSize: '20px' }}
        >
          Profile
        </h5>
        <form onSubmit={subProfile}>
          <div className="profileDiv">
            <div className={``} style={{ width: "100%" }}>
              <div className={`p-2`}>


                <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row gap-4">
                  <div className="custom-form-width" >
                    <p className={`d-flex inputProfile`}>
                      <span
                        className={`iconCreator flex justify-center items-center text-white`}
                      >
                        <MdAccountCircle className={`text-light`} />
                      </span>
                      <input
                        className={`creatorsInput py-2 ps-2 rounded-e-md`}
                        type="text"
                        name="name"
                        placeholder="Admin Name"
                        defaultValue={singleAdmin?.name}
                      />
                    </p>
                    <p className={`d-flex inputProfile`}>
                      <span
                        className={`iconCreator flex justify-center items-center text-white`}
                      >
                        <MdOutlineAlternateEmail className={`text-light`} />
                      </span>
                      <input
                        className={`creatorsInput py-2 ps-2 rounded-e-md`}
                        style={{ textTransform: "lowercase" }}
                        type="text"
                        name="username"
                        placeholder="Username"
                        defaultValue={singleAdmin?.username}
                      />
                    </p>
                    <p className={`d-flex inputProfile`}>
                      <span
                        className={`iconCreator flex justify-center items-center text-white`}
                      >
                        <GrMail className={`text-light`} />
                      </span>
                      <input
                        className={`creatorsInput py-2 ps-2 rounded-e-md`}
                        type="email"
                        name="email"
                        placeholder="email"
                        defaultValue={singleAdmin?.email}
                      />
                    </p>
                    <p
                      className={`flex flex-row border inputProfile creatorsInput rounded-md`}
                    >
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="SG"
                        value={valueProfilePhn}
                        onChange={setValueProfilePhn}
                        className={`countryInput text-black py-2 px-2 w-full`}
                      />
                    </p>

                    {/* 2nd part below the logo left part  */}
                    <p
                      className={`flex flex-row inputProfile`}
                      style={{ width: "100%" }}
                    >
                      <span className={`inputWpass`}>
                        <span
                          className={`iconCreator flex justify-center items-center text-white`}
                        >
                          <BiLockOpen className={`text-light`} />
                        </span>
                        <input
                          className={`creatorsInput password_input py-2 ps-2 flex-grow`}
                          type={visibleCPassword ? "text" : "password"}
                          name="currentPassword"
                          placeholder="Current Password"
                        />
                        <button
                          type="button"
                          onClick={() => setVisibleCPassword(!visibleCPassword)}
                          className={`iconBoxBtn password_togoler text-center text-white`}
                        >
                          {visibleCPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </button>
                      </span>
                    </p>
                    <p className={`flex flex-row inputProfile`}>
                      <span className={`inputWpass`}>
                        <span
                          className={`iconCreator flex justify-center items-center text-white`}
                        >
                          <BiLockOpen className={`text-light`} />
                        </span>
                        <input
                          className={`creatorsInput password_input py-2 ps-2 flex-grow`}
                          type={visibleEnPassword ? "text" : "password"}
                          name="password"
                          placeholder="Enter New Password"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setVisibleEnPassword(!visibleEnPassword)
                          }
                          className={`iconBoxBtn password_togoler text-center text-white`}
                        >
                          {visibleEnPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </button>
                      </span>
                    </p>
                    <p className={`flex flex-row inputProfile`}>
                      <span className={`inputWpass`}>
                        <span
                          className={`iconCreator flex justify-center items-center text-white`}
                        >
                          <AiOutlineEyeInvisible className={`text-light`} />
                        </span>
                        <input
                          className={`creatorsInput password_input py-2 ps-2 flex-grow`}
                          type={visibleCnPassword ? "text" : "password"}
                          name="cPassword"
                          placeholder="Confirm New Password"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setVisibleCnPassword(!visibleCnPassword)
                          }
                          className={`iconBoxBtn password_togoler text-center text-white`}
                        >
                          {visibleCnPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </button>
                      </span>
                    </p>
                  </div>
                  {/* right side  */}
                  <div className="custom-form-width" >
                    <img 
                    src={singleAdmin?.avatar} 
                    style={{ height: "130px" }} />

                    <p className={`flex flex-row inputProfile mt-4`}>
                      <input
                        className={`creatorsInput1 password_input py-2 ps-2 flex-grow`}
                        type="file"
                        name="avatar"
                        accept="image/*"
                      />
                    </p>
                  </div>

                </div>
              </div>

            </div>
            {/*  Actions buttons  */}
            <div className="d-flex gap-2 justify-content-center">
            <button
            onClick={() => {
                navigate('/admin/adminUser')
            }}
            className="btn btn-danger">Cancel</button>
              <button className="btn btn-primary">Save</button>
            </div>
          </div>
        </form>      
      </div>
    </>
  );
};

export default DashboardAdminEditProfile;