import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import { Button, Form, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import logo from '../../Assets/Images/ds-logo.png'

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const email = form.email.value;
    const password = form.password.value;

    axios.post("https://backend.whalesai.com/api/v1/admin/login", {
      email: email,
      password: password,
    })
      .then(res => {
        if (res.status === 200) {
          navigate(`/admin/otp/${res?.data?.token}/${email}`);
          localStorage.setItem('AiAdmin', res?.data?.token);
        }
      })
      .catch(err => {
        swal({
          title: "Attention",
          text: `${err.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  return (
    <div className="handleTheLoginBody" style={{paddingTop: '50px'}}>
      <Container className="pt-5 forCard p-5 rounded mx-auto" >
        <div className="mx-auto text-center" style={{ paddingTop: "20px", }}>
          <img src={logo} className="handleLogoLogin mx-auto" alt="logo" />
        </div>
        <p style={{fontSize: '20px', marginBottom: '20px'}} className="text-center fw-bold mt-2">Sign In</p>
        <Form onSubmit={handleSubmit} noValidate className="mt-1">
          <Form.Group controlId="email" className="mb-3">
            <InputGroup>
              <InputGroup.Text className="bg-dark border-0 text-white">
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
                style={{ textTransform: "lowercase" }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="password" className="mb-3">
            <InputGroup>
              <InputGroup.Text className="bg-dark border-0 text-white">
                <FontAwesomeIcon icon={faLock} />
              </InputGroup.Text>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="password"
                name="password"
                required
              />
              <InputGroup.Text className="bg-dark text-center border-0 cursor-pointer text-white" role="button" onClick={handleClickShowPassword}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 text-end">
            <Link to="/admin/forgetpassword" className="text-decoration-none">Forgot password?</Link>
          </Form.Group>
          <Button variant="danger" type="submit" className="w-100 mt-3">
            Sign In
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default Login;
