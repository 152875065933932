import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import UserAuthProvider from "./Contexts/UserAuthContext";
import AdminAuthProvider from "./Contexts/AdminAuthContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <UserAuthProvider>
    <AdminAuthProvider>
      <App />
    </AdminAuthProvider>
  </UserAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
 
// import React from "react";
// import { createRoot } from "react-dom/client";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";
// import "./global.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import UserAuthProvider from "./Contexts/UserAuthContext";
// import AdminAuthProvider from "./Contexts/AdminAuthContext";

// const container = document.getElementById("root");
// const root = createRoot(container);

// // Check if the app is in maintenance mode
// if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
//   root.render(
//     <div style={{ textAlign: 'center', padding: '50px' }}>
//       <h1>Maintenance Mode</h1>
//       <p>The site is currently under maintenance. Please check back later.</p>
//     </div>
//   );
// } else {
//   root.render(
//     <UserAuthProvider>
//       <AdminAuthProvider>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </AdminAuthProvider>
//     </UserAuthProvider>
//   );
// }

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
