import React, { useState } from 'react';
import axios from "axios";
import swal from 'sweetalert';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../Assets/Images/ds-logo.png'

const ResetPassword2 = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios.post("https://backend.whalesai.com/api/v1/admin/reset-password", { newPassword: data.get('password') }, {
      headers: {
        'authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        if (res.status === 200) {
          swal({
            title: "Success",
            text: `${res.data.message}`,
            icon: "success",
            button: "OK!",
            className: "modal_class_success",
          });
          navigate("/admin/login");
        }
      })
      .catch(err => {
        swal({
          title: "Attention",
          text: `${err.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  return (
    <div className='handleTheLoginBody'>
      <Container className='pt-5 forCard p-5 rounded mx-auto'>
        <Row className='justify-content-center'>
          <Col xs={12} md={12}>
            <div className='text-center mb-4'>
              <img src={logo} className='handleLogoLogin' alt="logo" />
              <p style={{fontSize: '20px'}} className='mt-2 fw-bold'>Please enter your new password</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <InputGroup className="mb-3 mx-auto"
              style={{width: '70%'}}
              >
                <Form.Control
                  aria-label="Enter New Password"
                  placeholder="Enter New Password"
                  name="password"
                  className='bg-white mx-auto'
                  
                  type={visiblePassword ? "text" : "password"}
                  required
                />
                <InputGroup.Text
                  role="button"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                  className="bg-dark text-white"
                >
                  {visiblePassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                </InputGroup.Text>
              </InputGroup>
              <div className='d-flex justify-content-center gap-3'>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResetPassword2;
