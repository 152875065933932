import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC3cmJYQBY1Mry9b9GQO-2XNRRgPzPyi7E",
    authDomain: "whalesai-3b44b.firebaseapp.com",
    projectId: "whalesai-3b44b",
    storageBucket: "whalesai-3b44b.appspot.com",
    messagingSenderId: "374656989149",
    appId: "1:374656989149:web:510cd766a6d4f462c3a834",
    // measurementId: "G-NW81ZN0QW5"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);