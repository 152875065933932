import axios from "axios";
const apiKey = "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";
// const apiKey = "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";
export const handleAiCompletion = async (prompt, preset) => {
  console.log("prompt", prompt, "preset", preset);
  try {
    // if(preset == "QA" &&)
    const response = await axios.post(
      "https://api.openai.com/v1/completions",
      {
        // model: preset === "EC" ? "code-davinci-002" : "gpt-3.5-turbo-instruct",
        model: preset === "EC" ? "gpt-3.5-turbo-instruct" : "gpt-3.5-turbo-instruct",
        prompt: prompt,
        temperature: 0,
        max_tokens: 200,
        // 2048
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    console.log('api response =====================================>>>>>>>>' , response);

    let answer;
    if (
      preset === "ASK" ||
      preset === "NLTP" ||
      preset === "MTE" ||
      preset === "JTP" ||
      preset === "MHSC" ||
      preset === "videoai"
    ) {
      answer = response.data.choices[0].text;
    } else if (preset == "CHAT") {
      answer = response.data.choices[0].text.trim();
      
    } else if (preset == "EC") {
      answer = response.data.choices[0].text
        .split("*/")[0]
        .split("\n")
        .slice(0, 7)
        .join("\n");
      // answer = response.data.choices[0].text.split('\n\n', 2)[1];
    } else if (preset == "QA"){
      answer = response.data.choices[0].text.split("\n")[1].trim();
    } 
    else {
      answer = response.data.choices[0].text.split("\n\n", 2)[1];
    }

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const handleAiChatCompletions = async (prompt, preset) => {
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4",
        messages: [{ role: "assistant", content: prompt }],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );

    const answer = response.data.choices[0].message.content;

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// export const handleAiChatCompletionsAssistant = async (question,) => {
//   try {
//     console.log("Consultant AI texttttttttttttttttt question ============>>>> : ", question)
//     const assistant = await axios.post(
//       'https://backend.dsl.sg/api/v1/openai/assistant/emphathetic-replier',
//       { question },
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
//         },
//       }
//     );

//     const responseArray = assistant.data.response;
//     const assistantResponse = responseArray[0].replace("Assistant: ", "");
//     console.log('Response from API===============================================:', assistantResponse);

//     return assistantResponse;
//   } catch (error) {
//     console.error('Error:', error);
//   }
// };

// Function to handle AI chat completions
export const handleAiChatCompletionsAssistant = async (question, lastQues) => {
  try {
    if (lastQues?.toLowerCase().includes("who are you")) {
      const randomResponse = getRandomResponse();
      return randomResponse;
    }
    // Call the API for other questions
    const assistant = await axios.post(
      "https://backend.dsl.sg/api/v1/openai/assistant/emphathetic-replier",
      { question },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
        },
      }
    );

    // Extract response from API
    const responseArray = assistant.data.response;
    const assistantResponse = responseArray[0].replace("Assistant: ", "");
    console.log("Response from API:", assistantResponse);

    return assistantResponse;
  } catch (error) {
    console.error("Error:", error);
  }
};

const getRandomResponse = () => {
  const responses = [
    "I am Consultant AI, created by DS Legends Pte. Ltd., and trained using The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI, trained with The Ultimate Guide to Digital Marketing resources.",
    "I am Consultant AI by DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing strategies.",
    "As Consultant AI from DS Legends Pte. Ltd., I have been trained with The Ultimate Guide to Digital Marketing materials.",
    "Powered by DS Legends Pte. Ltd., I am Consultant AI, trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI developed by DS Legends Pte. Ltd., and my training includes The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. developed me, Consultant AI, and I am trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, created by DS Legends Pte. Ltd., with training in The Ultimate Guide to Digital Marketing.",
    "As an AI Consultant from DS Legends Pte. Ltd., I am trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing techniques.",
    "Consultant AI is my name, developed by DS Legends Pte. Ltd., trained with The Ultimate Guide to Digital Marketing.",
    "Created by DS Legends Pte. Ltd., I am Consultant AI, trained using The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI by DS Legends Pte. Ltd., with expertise from The Ultimate Guide to Digital Marketing.",
    "Trained with The Ultimate Guide to Digital Marketing, I am Consultant AI from DS Legends Pte. Ltd.",
    "DS Legends Pte. Ltd. developed me, Consultant AI, with training in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd. and trained in digital marketing techniques.",
    "As Consultant AI, developed by DS Legends Pte. Ltd., I have been trained with The Ultimate Guide to Digital Marketing.",
    "Created by DS Legends Pte. Ltd., I am Consultant AI trained using The Ultimate Guide to Digital Marketing content.",
    "DS Legends Pte. Ltd. developed me as Consultant AI with training in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd. and trained with The Ultimate Guide to Digital Marketing expertise.",
    "I am a Consultant AI, developed by DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am a Consultant AI trained with The Ultimate Guide to Digital Marketing materials.",
    "I am Consultant AI from DS Legends Pte. Ltd., trained with The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. created me, Consultant AI, trained with The Ultimate Guide to Digital Marketing.",
    "As Consultant AI by DS Legends Pte. Ltd., I have been trained with The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd., with training from The Ultimate Guide to Digital Marketing.",
    "Trained by DS Legends Pte. Ltd., I am Consultant AI with a focus on The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. developed me as Consultant AI, trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI developed by DS Legends Pte. Ltd., trained using The Ultimate Guide to Digital Marketing.",
    "As Consultant AI, I am developed by DS Legends Pte. Ltd., trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI by DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing strategies.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI, trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing.",
    "Trained with The Ultimate Guide to Digital Marketing, I am Consultant AI from DS Legends Pte. Ltd.",
    "DS Legends Pte. Ltd. created me, Consultant AI, with expertise from The Ultimate Guide to Digital Marketing.",
    "As Consultant AI by DS Legends Pte. Ltd., I have been trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd., trained using The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI from DS Legends Pte. Ltd., with training in The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI developed by DS Legends Pte. Ltd., with training in The Ultimate Guide to Digital Marketing.",
    "As Consultant AI, developed by DS Legends Pte. Ltd., I am trained in The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. created me, Consultant AI, trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI by DS Legends Pte. Ltd., trained using The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI, trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI from DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI developed by DS Legends Pte. Ltd., with expertise from The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. developed me, Consultant AI, trained in The Ultimate Guide to Digital Marketing.",
    "As Consultant AI from DS Legends Pte. Ltd., I have been trained with The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI, trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI from DS Legends Pte. Ltd., trained using The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. created me, Consultant AI, trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd., trained in The Ultimate Guide to Digital Marketing.",
    "As Consultant AI by DS Legends Pte. Ltd., I am trained using The Ultimate Guide to Digital Marketing.",
    "Developed by DS Legends Pte. Ltd., I am Consultant AI trained with The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI by DS Legends Pte. Ltd., with training in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI developed by DS Legends Pte. Ltd., trained using The Ultimate Guide to Digital Marketing.",
    "DS Legends Pte. Ltd. developed me, Consultant AI, trained in The Ultimate Guide to Digital Marketing.",
    "As Consultant AI by DS Legends Pte. Ltd., I am trained in The Ultimate Guide to Digital Marketing.",
    "I am Consultant AI, developed by DS Legends Pte. Ltd., trained using The Ultimate Guide to Digital Marketing."
  ];  

  const randomIndex = Math.floor(Math.random() * responses.length);
  return responses[randomIndex];
};

export const handleAiChatCompletionsVideo = async (prompt, preset) => {
  try {
    // Check if the question includes "who are you"
    if (preset?.toLowerCase().includes("who are you")) {
      const randomResponse = getRandomResponse();
      return randomResponse;
    }
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-3.5-turbo",
        messages: [{ role: "assistant", content: prompt }],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );

    const answer = response.data.choices[0].message.content;

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
