import React, { useContext, useEffect, useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import PageHeading from "../../Components/PageHeading/PageHeading";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calenders.css";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import countryList from "react-select-country-list";
import {
  MdContentCopy,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineEmojiEmotions,
  MdOutlineToggleOff,
  MdOutlineToggleOn,
} from "react-icons/md";
import { IoMdPhotos } from "react-icons/io";
import {
  FaArrowAltCircleDown,
  FaArrowAltCircleUp,
  FaArrowCircleDown,
  FaInfoCircle,
  FaVideo,
} from "react-icons/fa";
import CustomTimePicker from "../../Components/shared/CustomTimePicker";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Components/Firebase/firebase.config";
import {
  FacebookAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from "firebase/auth";
import SampleVideoModal from "../../Components/VideoAi/SampleVideoModal";
import SampleImageModal from "../../Components/ImageSample/ImageSample";
import ShortText from "../../Components/ImageSample/ShortTextModal";
import LongText from "../../Components/ImageSample/LongText";
import SampleComic from "../../Components/ImageSample/ComicSample";
import SampleNews from "../../Components/ImageSample/NewsSample";
import SampleVideo from "../../Components/ImageSample/VideoSample";
import SampleShortArticleImage from "../../Components/ImageSample/ShortImage";
import SampleLongArticleImage from "../../Components/ImageSample/LongImage";
import SampleLargeVideo from "../../Components/ImageSample/LargeVideoSample";
import SampleLargeNews from "../../Components/ImageSample/LargeNewsSample";
import SampleLargeComic from "../../Components/ImageSample/LargeComicSample";
import Swal from "sweetalert2";

const ContentTypes = [
  "Informative post",
  "Quote",
  "Motivational message",
  "Captivating storie",
  "Poem",
  "Tips and how-to guide",
  "Educational content",
  "Poll and question",
  "Quiz and challenge",
  "Article",
];

const Calendars = () => {
  const [scheduleData, setScheduleData] = useState(null);
  const [activeButton, setActiveButton] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [scheduled, setScheduled] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [isEditingCountry, setIsEditingCountry] = useState(false);
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [showPlatforms, setShowPlatforms] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [toggledPlatforms, setToggledPlatforms] = useState([]);
  const [timer, setTimer] = useState(null);
  const [todaysContent, setTodaysContent] = useState();
  const [currentDateContent, setCurrentDateContent] = useState();
  const [time, setTime] = useState(() => {
    const foundSchedule = scheduleData?.find(
      (item) => item.platform === buttons[activeButton]
    );
    return foundSchedule
      ? foundSchedule?.contentCategory[indexNumber]?.timeOfPosting
      : "09:00";
  });
  const [nextDate, setNextDate] = useState();
  const [info, setInfo] = useState(false);
  const [infoToggole, setInfoToggle] = useState(false);
  const [post, setPost] = useState([]);
  const [uniqueContent, setUniqueContent] = useState([]);
  const [contentToggle, setContentToggle] = useState(false);
  const [clickedDay, setClickedDay] = useState();
  const [showContentType, setShowContentType] = useState("");
  const [indexNumber, setIndexNumber] = useState(0);
  const [socialSchedule, setSocialSchedule] = useState(false);
  const [isChanging, setisChanging] = useState(false);
  const [facebookAccessToken, setFacebookAccessToken] = useState(null);
  const [isFBLoaded, setIsFBLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [modalShort, setModalShort] = useState(false);
  const [modalLong, setModalLong] = useState(false);
  const [modalComic, setModalComic] = useState(false);
  const [modalNews, setModalNews] = useState(false);
  const [modalShortImage, setModalShortImage] = useState(false);
  const [modalLongImage, setModalLongImage] = useState(false);
  const [modalLargeTextVideo, setModalLargeTextVideo] = useState(false);
  const [modalLargeNewsVideo, setModalLargeNewsVideo] = useState(false);
  const [modalLargeComicVideo, setModalLargeComicVideo] = useState(false);
  const [isOwnUpload, setIsOwnUpload] = useState(false);
  const navigate = useNavigate();
  const { AIuser, setAIuser } = useContext(UserAuthContext);
  console.log("user", AIuser);
  //  from cutom time picker
  const [hourUpdate, setHourUpdate] = useState(false);
  const [minUpdate, setMinUpdate] = useState(false);

  const handleHourUpdate = (status) => {
    setHourUpdate(status);
  };

  const handleMinUpdate = (status) => {
    setMinUpdate(status);
  };

  const handleInfoClick = () => {
    const randomIndex = Math.floor(Math.random() * ContentTypes.length);
    setShowContentType(ContentTypes[randomIndex]);
  };
  const closeModal = () => {
    setShowContentType("");
  };

  const buttons = [
    "Facebook",
    "Instagram",
    "Threads",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "YouTube",
    "Pinterest",
    "Medium",
    "Telegram",
  ];

  const buttonColors = [
    "#95B9C9",
    "#39ACEF",
    "#0699E8",
    "#488AC7",
    "#367ECA",
    "#6960ED",
    "#1669C7",
    "#0040C4",
    "#0000FE",
    "#151A8F",
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#011225",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#011225" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "#011225",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
  };
  const thisDate = new Date();
  const thisMonth = thisDate.toLocaleString("default", { month: "long" });
  const thisYear = thisDate.getFullYear();
  const firstSchedule =
    scheduleData && scheduleData?.length > 0 ? scheduleData[0] : null;
  const today = new Date();

  const givenDate = new Date(selectedDate);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const day = String(tomorrow.getDate()).padStart(2, "0");
  const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  const year = tomorrow.getFullYear();

  const tomorrowDate = `${day}-${month}-${year}`;
  console.log("the next date", tomorrowDate);

  const renderTileContent = ({ date, view }) => {
    if (
      scheduleData &&
      buttons[activeButton] ===
        scheduleData?.find((item) => item.platform === buttons[activeButton])
          ?.platform
    ) {
      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("-").map(Number);
        return new Date(year, month - 1, day);
      };

      const activePlatformSchedule = scheduleData?.find(
        (item) => item.platform === buttons[activeButton]
      );
      const startDate = parseDate(activePlatformSchedule?.startingDate);
      const endDate = parseDate(activePlatformSchedule?.endDate);

      if (
        date >= startDate &&
        date <= endDate &&
        scheduleData?.length !== 0 &&
        view === "month"
      ) {
        return (
          <div>
            <MdContentCopy style={{ color: "black" }} />
          </div>
        );
      }
    }
    return null;
  };
  const provider = new FacebookAuthProvider();
  provider.addScope("public_profile");
  provider.addScope("email");
  provider.addScope("pages_manage_posts");
  provider.addScope("pages_show_list");

  //for intial time
  // useEffect(() => {
  //   const foundSchedule = scheduleData?.find(
  //     (item) => item.platform === buttons[activeButton]
  //   );
  //   if (foundSchedule?.platform === "Facebook") {
  //     if (foundSchedule) {
  //       const facebookTime =
  //         foundSchedule?.contentCategory[indexNumber]?.timeOfPosting || "09:00";
  //       setTime(facebookTime);
  //     }
  //   }
  // }, [scheduleData, buttons, activeButton, indexNumber]);

  // country option
  useEffect(() => {
    setCountryOptions(countryList().getData());
  }, []);

  useEffect(() => {
    if (scheduleData?.length > 0) {
      const allPlatforms = scheduleData.map((item) => item.platform);
      const uniquePlatforms = [...new Set(allPlatforms)];
      setToggledPlatforms(uniquePlatforms);
    }
  }, [scheduleData]);

  useEffect(() => {
    const activePlatform = buttons[activeButton];
    if (activeButton === 0 || activeButton === 4) {
      setUniqueContent([
        "Short Article with hashtags",
        "Long Article with hashtags",
        "1:1 Image with text and hashtags",
        "1:1 Text Video with text and hashtags",
        "1:1 News Video with text and hashtags",
        "1:1 Comic Video with text and hashtags",
      ]);
    } else if (activeButton === 1 || activeButton === 2 || activeButton === 7) {
      setUniqueContent([
        "1:1 Image with text and hashtags",
        "1:1 Text Video with text and hashtags",
        "1:1 News Video with text and hashtags",
        "1:1 Comic Video with text and hashtags",
      ]);
    } else if (activeButton === 5 || activeButton === 6) {
      setUniqueContent([
        "16:9 Text Video with text and hashtags",
        "16:9 News Video with text and hashtags",
        "16:9 Comic Video with text and hashtags",
      ]);
    } else if (activeButton === 8) {
      setUniqueContent([
        "Short Article with hashtags (1:1 image)",
        "Long Article with hashtags (1:1 image)",
      ]);
    } else {
      setUniqueContent([
        "Short Article with hashtags",
        "1:1 Image with text and hashtags",
        "1:1 Text Video with text and hashtags",
        "1:1 News Video with text and hashtags",
        "1:1 Comic Video with text and hashtags",
      ]);
    }
  }, [activeButton]);

  const handleClearTimer = () => {
    clearInterval(timer);
  };

  const handleDateChange = (date) => {
    setIsChnaged(false);
    setSelectedDate(date);
    const activePlatform = buttons[activeButton];
    const platformSchedule = scheduleData?.find(
      (item) => item.platform === activePlatform
    );
    console.log("platform Schedule", platformSchedule);
    if (platformSchedule) {
      const startDateParts = platformSchedule?.startingDate.split("-");
      const startDate = new Date(
        startDateParts[2],
        startDateParts[1] - 1,
        startDateParts[0]
      );
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);

      if (date >= startDate && date <= endDate) {
        const timeDiff = date.getTime() - startDate.getTime();
        const dayNumber = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
        setClickedDay(dayNumber);
        const handleButtonClickSWAL = (value) => {
          swal.close();
        };
        const okButton = document.createElement("button");
        okButton.textContent = "OK";
        okButton.className = "modal-button ok-button";
        okButton.addEventListener("click", () => handleButtonClickSWAL("OK"));
        setTime(
          platformSchedule?.contentCategory[dayNumber - 1]?.timeOfPosting
        );
        setTodaysContent(platformSchedule?.contentCategory[dayNumber - 1]);
        setInfo(true);
      } else {
      }
    } else {
    }
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
    const currentMonth = activeStartDate.toLocaleString("default", {
      month: "long",
    });
    const currentYear = activeStartDate.getFullYear();
    setCurrentMonth(currentMonth);
    setCurrentYear(currentYear);
    console.log(`Changed Month: ${currentMonth}, Changed Year: ${currentYear}`);
  };

  console.log("Scheduled --->", scheduleData);

  const handleButtonClick = (index) => {
    setIsChnaged(false);
    const today = new Date();
    setActiveButton(index);
    setSelectedDate(today);
    setActiveStartDate(today);

    //  if(scheduleData){
    //   if (scheduleData?.find((item) => item.platform === buttons[index])) {
    //     setSocialSchedule(true);
    //     if(indexNumber == -1) setTime("09:00")
    //     else{
    //     setTime(
    //       scheduleData?.find((item) => item.platform === buttons[index])
    //         .contentCategory[indexNumber]?.timeOfPosting
    //     );
    //   }
    //   } else {
    //     setTime("09:00");
    //     setSocialSchedule(false);
    //   }
    //  } else {
    //   setTime("09:00");
    //  }
    // Default time

    let timeToSet = "09:00";

    if (scheduleData) {
      const platformData = scheduleData.find(
        (item) => item.platform === buttons[index]
      );

      if (platformData) {
        setSocialSchedule(true);

        if (indexNumber === -1) {
          timeToSet = "09:00";
        } else {
          timeToSet = platformData.contentCategory[indexNumber]?.timeOfPosting;
        }
      } else {
        setSocialSchedule(false);
      }
    }

    setTime(timeToSet);
  };
  // time and date formatting for post the schedule
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleReSchedulerClick = async () => {
    const platform = buttons[activeButton];
    swal({
      text: `Do you want our Re-Scheduler AI to automatically generate and post daily content to ${platform == "Twitter" ? "Twitter/X" : platform}?`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      className: "modal_class_success",
    }).then(async (willPut) => {
      if (willPut) {
        swal({
          text: `Scheduler AI has planned contents for ${platform == "Twitter" ? "Twitter/X" : platform}. Please click on individual date to see what type of content is scheduled.`,
          icon: "success",
          className: "modal_class_success",
        });

        const existingSchedule = scheduleData?.find(
          (item) => item.platform === platform
        );
        const data = {
          userId: AIuser._id,
          email: AIuser.email,
          timeOfPosting: time,
          startingDate: formatDate(new Date(Date.now() + 86400000)),
          platform: platform,
          country: AIuser?.country,
        };

        try {
          if (existingSchedule) {
            const deleteResponse = await fetch(
              `https://backend.whalesai.com/api/v1/social-schedule/schedules/${existingSchedule._id}`,
              {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
              }
            );
            if (!deleteResponse.ok) {
              throw new Error("Failed to delete existing schedule");
            }
          }
          const postResponse = await fetch(
            `https://backend.whalesai.com/api/v1/social-schedule/schedules`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }
          );

          if (!postResponse.ok) {
            throw new Error("Failed to post new schedule");
          }

          await getScheduleByEmail(AIuser?.email); // call the schedule data after scheduling
          console.log("Schedule updated successfully", data);
        } catch (error) {
          console.error("Error:", error);
          swal({
            text: `Failed to schedule content. Please try again later.`,
            icon: "error",
            className: "modal_class_error",
          });
        }
      }
    });
  };
  // workingggggggggggggggggggggggggggg
  const handleSchedulerAIClick = async () => {
    const platform = buttons[activeButton];
    swal({
      text: `Do you want our Scheduler AI to automatically generate and post daily content to ${platform == "Twitter" ? "Twitter/X" : platform}?`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      className: "modal_class_success",
    }).then(async (willPost) => {
      if (willPost) {
        setScheduled(true);
        swal({
          text: `Scheduler AI has planned contents for ${platform}. Please click on individual date to see what type of content is scheduled.`,
          icon: "success",
          className: "modal_class_success",
        });
        const data = {
          userId: AIuser._id,
          email: AIuser.email,
          // timeOfPosting: formatTime(time),
          timeOfPosting: time,
          startingDate: formatDate(new Date(Date.now() + 86400000)),
          platform: platform,
          country: AIuser?.country,
        };

        try {
          console.log(data, AIuser, "ffrom post...");
          if (scheduleData?.length !== 10) {
            const postResponse = await fetch(
              "https://backend.whalesai.com/api/v1/social-schedule/schedules",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
            if (!postResponse.ok) {
              throw new Error("Failed to create schedule");
            }
            await getScheduleByEmail(AIuser?.email); // call the scheduled data after scheduling
            console.log("Schedule created successfully", data);
          } else {
            swal({
              text: `You have already selected 4 social Media platform. You cannot select more than 4 platforms.`,
              icon: "warning",
              className: "modal_class_success",
            });
          }
        } catch (error) {
          console.error("Error:", error);
          swal({
            text: `Failed to schedule content. Please try again later.`,
            icon: "error",
            className: "modal_class_error",
          });
        }
      }
    });
  };

  console.log("index", indexNumber);

  const sendDataToBackend = async (data) => {
    try {
      if (scheduleData?.length !== 10) {
        const postResponse = await fetch(
          "https://backend.whalesai.com/api/v1/social-schedule/schedules",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!postResponse.ok) {
          throw new Error("Failed to create schedule");
        }

        await getScheduleByEmail(AIuser?.email); // call the scheduled data after scheduling
        console.log("Schedule created successfully", data);
      } else {
        swal({
          text: `You have already selected 4 social Media platform. You cannot select more than 4 platforms.`,
          icon: "warning",
          className: "modal_class_success",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      swal({
        text: `Failed to schedule content. Please try again later.`,
        icon: "error",
        className: "modal_class_error",
      });
    }
  };

  const getScheduleByEmail = async (email) => {
    try {
      const response = await fetch(
        `https://backend.whalesai.com/api/v1/social-schedule/schedules/email/${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch schedule");
      }
      const data = await response.json();
      setScheduleData(data);
      console.log("Schedule data:", data);
    } catch (error) {
      console.error("Error:", error);
      swal({
        text: `Failed to fetch schedule. Please try again later.`,
        icon: "error",
        className: "modal_class_success",
      });
    }
  };
  // get the schedule
  useEffect(() => {
    if (AIuser?.email) {
      getScheduleByEmail(AIuser?.email);
    }
  }, [AIuser?.email]);

  // show platform ----------------------TODO--------------
  const handleShowPlatform = () => {
    if (firstSchedule) {
      setShowPlatforms(!showPlatforms);
    } else {
      swal({
        text: "Please prioritize scheduling your content first.",
        className: "modal_class_success",
        buttons: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "modal_class_success",
            closeModal: true,
          },
        },
      });
    }
  };
  // page's last button 'platform chnage '
  const handleToggle = (platform) => {
    if (post?.includes(platform)) {
      setPost(post?.filter((item) => item !== platform));
    } else {
      if (post.length >= 4) {
        swal({
          text: `You have selected '${post.join(", ")}'.\n You can not select more than 4 platforms.`,
          buttons: false,
          timer: 3000,
          icon: "warning",
          className: "modal_class_success",
          content: {
            element: "span",
            attributes: {
              style: "color: #fff; padding: 20px; border-radius: 5px;",
            },
          },
        });
      } else {
        setPost([...post, platform]);
      }
    }
  };

  const handleCountryChange = async (selectedOption) => {
    const newCountry = selectedOption.label;
    setUserCountry(newCountry);
    setIsEditingCountry(false);
    const aiUserData = localStorage.getItem("aiUser");
    if (!aiUserData) {
      console.error("No aiUser data found in localStorage");
      return;
    }
    const requestData = {
      country: newCountry,
    };
    try {
      const response = await axios.put(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/update/country",
        requestData,
        {
          headers: {
            Authorization: `Bearer ${aiUserData}`,
          },
        }
      );
      setisChanging(false);
      console.log(
        "Country updated successfully......:",
        response?.data?.result
      );
      axios
        .get(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/current-user",
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setAIuser(res.data.result);
            // setisChanging(true);
          }
        })
        .finally(() => {});
      await getScheduleByEmail(AIuser?.email).then(setisChanging(false));
    } catch (error) {
      console.error("Error updating country:", error);
    }
  };

  const handleToggleInfo = () => {
    if (isDisabled) {
      swal({
        text: `You can't make changes today.`,
        icon: "warning",
        className: "modal_class_success",
      });
    } else {
      setInfoToggle(!infoToggole);
    }
  };

  const [isChnaged, setIsChnaged] = useState(false);

  const handleCheckboxClick = async (content) => {
    setIsChnaged(true);
    try {
      const activeSchedule = scheduleData.find(
        (item) => item.platform === buttons[activeButton]
      );
      if (!activeSchedule) {
        throw new Error("Active schedule not found");
      }

      const updatedData = {
        contentCategoryId: todaysContent?._id,
        newContent: content,
      };

      console.log("Updating schedule with:", updatedData);
      const response = await axios.put(
        `https://backend.whalesai.com/api/v1/social-schedule/schedules/${activeSchedule._id}`,
        updatedData
      );

      if (response.status === 200) {
        // Update local schedule data
        setScheduleData((prevScheduleData) => {
          getScheduleByEmail(AIuser?.email); // Fetch updated schedule data

          const updatedScheduleData = prevScheduleData.map((schedule) => {
            if (schedule._id === activeSchedule._id) {
              return {
                ...schedule,
                contentCategory: schedule.contentCategory.map((category) => {
                  if (category._id === updatedData.contentCategoryId) {
                    return {
                      ...category,
                      content: updatedData.newContent,
                    };
                  }
                  return category;
                }),
              };
            }
            return schedule;
          });

          return updatedScheduleData;
        });

        getScheduleByEmail(AIuser?.email);

        setTodaysContent((prevTodaysContent) => ({
          ...prevTodaysContent,
          content: updatedData.newContent,
        }));
        setContentToggle(false);
      } else {
        throw new Error(
          `Failed to update schedule with status ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error updating content:", error.message);
    }
  };

  const handlePay = (data) => {
    console.log("pay...", data);
    let ammount;
    if (data?.length === 1) ammount = 30.0;
    else if (data?.length === 2) ammount = 48.0;
    else if (data?.length === 3) ammount = 63.0;
    else if (data?.length === 4) ammount = 72.0;
    console.log(ammount);
    if (data?.length !== 0) {
      navigate("/social-media-subscription", {
        state: { data: { ...data, platform: [data], ammount } },
        replace: true,
      });
    }
  };

  // Function to compare dates without time
  const isTodayOrPast = (date1, date2) => {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return date1.getTime() <= date2.getTime();
  };
  const isDisabled = isTodayOrPast(givenDate, today);

  const calculateDayNumber = (startingDateStr, todayDateStr) => {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr?.split("-").map(Number);
      return new Date(year, month - 1, day); // month is 0-indexed
    };

    const startingDate = parseDate(startingDateStr);
    const todayDate = parseDate(todayDateStr);

    const timeDiff = todayDate.getTime() - startingDate.getTime();
    const dayNumber = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
    setIndexNumber(dayNumber - 1);
    return dayNumber;
  };

  useEffect(() => {
    if (scheduleData && buttons[activeButton]) {
      const activePlatform = buttons[activeButton];
      const platformSchedule = scheduleData.find(
        (item) => item.platform === activePlatform
      );

      if (platformSchedule) {
        const today = new Date();
        const formattedToday = `${String(today.getDate()).padStart(2, "0")}-${String(today.getMonth() + 1).padStart(2, "0")}-${today.getFullYear()}`;
        const currentDayNumber = calculateDayNumber(
          platformSchedule.startingDate,
          formattedToday
        );

        console.log("day number", currentDayNumber);

        if (
          platformSchedule.contentCategory &&
          currentDayNumber <= platformSchedule.contentCategory.length
        ) {
          setCurrentDateContent(
            platformSchedule.contentCategory[currentDayNumber]
          ); // Adjust index for 0-based array
        } else {
          setCurrentDateContent(null);
        }
      } else {
        setCurrentDateContent(null);
      }
    }
  }, [scheduleData, activeButton, buttons]);

  // console.log("today...", currentDateContent);

  console.log("From Custom Time picker =====> ", hourUpdate, minUpdate);

  const handleShowSample = (event) => {
    //  alert(content);
    console.log("From event ", event);
    if (event === "1:1 Text Video with text and hashtags") setModalShow(true);
    else if (event === "1:1 Image with text and hashtags") setModalImage(true);
    else if (event === "Short Article with hashtags") setModalShort(true);
    else if (event == "Long Article with hashtags") setModalLong(true);
    else if (event === "1:1 Comic Video with text and hashtags")
      setModalComic(true);
    else if (event === "1:1 News Video with text and hashtags")
      setModalNews(true);
    else if (event === "Short Article with hashtags (1:1 image)")
      setModalShortImage(true);
    else if (event === "Long Article with hashtags (1:1 image)")
      setModalLongImage(true);
    else if (event === "16:9 Text Video with text and hashtags")
      setModalLargeTextVideo(true);
    else if (event === "16:9 News Video with text and hashtags")
      setModalLargeNewsVideo(true);
    else if (event === "16:9 Comic Video with text and hashtags")
      setModalLargeComicVideo(true);
  };

  // useEffect(() => {
  //   const initialTimeout = setTimeout(() => {
  //     getScheduleByEmail(AIuser?.email);
  //     const interval = setInterval(() => {
  //       getScheduleByEmail(AIuser?.email);
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }, 5000);
  //   return () => clearTimeout(initialTimeout);
  // }, []);

  return (
    <div>
      <PageHeading title="CALENDARS" />
      <section>
        <ShortText setModalShort={setModalShort} modalShort={modalShort} />
        <LongText setModalLong={setModalLong} modalLong={modalLong} />
        <SampleVideo setModalShow={setModalShow} modalShow={modalShow} />
        <SampleNews setModalNews={setModalNews} modalNews={modalNews} />
        <SampleComic setModalComic={setModalComic} modalComic={modalComic} />
        <SampleImageModal
          setModalImage={setModalImage}
          modalImage={modalImage}
        />
        <SampleShortArticleImage
          setModalShortImage={setModalShortImage}
          modalShortImage={modalShortImage}
        />
        <SampleLongArticleImage
          setModalLongImage={setModalLongImage}
          modalLongImage={modalLongImage}
        />
        <SampleLargeVideo
          setModalLargeTextVideo={setModalLargeTextVideo}
          modalLargeTextVideo={modalLargeTextVideo}
        />
        <SampleLargeNews
          setModalLargeNewsVideo={setModalLargeNewsVideo}
          modalLargeNewsVideo={modalLargeNewsVideo}
        />
        <SampleLargeComic
          setModalLargeComicVideo={setModalLargeComicVideo}
          modalLargeComicVideo={modalLargeComicVideo}
        />
      </section>

      {/* SMP buttons  */}
      <div className="quick-links-container custom-tabs d-flex gap-2">
        {buttons.map((label, index) => (
          <button
            key={index}
            className={`btn custom rounded-lg ${
              activeButton === index ? "active" : ""
            }`}
            onClick={() => handleButtonClick(index)}
            style={{ backgroundColor: buttonColors[index] }}
          >
            <p className="tabs-item m-0">
              {" "}
              {label == "Twitter" ? "Twitter/X" : label}
            </p>
          </button>
        ))}
      </div>

      <div className="tabs-title">
        {buttons[activeButton] == "Twitter"
          ? "Twitter/X"
          : buttons[activeButton]}
      </div>
      <div
        className="text-center text-white"
        style={{ fontSize: "10px", marginBottom: "20px" }}
      >
        {" "}
        {scheduleData?.length !== 0 &&
        Array.isArray(scheduleData) &&
        scheduleData?.some(
          (item) => item.platform === buttons[activeButton]
        ) ? (
          <p className="text-center text-white">
            {" "}
            Scheduler AI has scheduled from <br />{" "}
            {
              scheduleData?.find(
                (item) => item.platform === buttons[activeButton]
              )?.startingDate
            }{" "}
            to{" "}
            {
              scheduleData?.find(
                (item) => item.platform === buttons[activeButton]
              )?.endDate
            }{" "}
          </p>
        ) : (
          <span> Scheduling Not Done </span>
        )}
      </div>
      {scheduled && (
        <p className="text-to-animate mx-auto mb-4">
          {" "}
          You can click on the particular date to view what will be posted.
        </p>
      )}
      {/* calender  */}
      <div
        className="calendar mb-1 pb-0 mx-auto"
        style={{ position: "relative" }}
      >
        <div>
          <div className="justify-content-center">
            <Col xs="auto" className="calendar-col">
              <div
                className="calendar-navigation"
                style={{ position: "relative" }}
              >
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  className="custom-calendar mx-auto"
                  next2Label={null}
                  prev2Label={null}
                  activeStartDate={activeStartDate}
                  onActiveStartDateChange={handleActiveStartDateChange}
                  tileContent={renderTileContent}
                />
              </div>
            </Col>
          </div>
        </div>
      </div>

      {/* Scheduler message after scheduling  */}
      <div className="mt-0 pt-0">
        {" "}
        {scheduleData?.length !== 0 &&
          Array.isArray(scheduleData) &&
          scheduleData?.some(
            (item) => item.platform === buttons[activeButton]
          ) && (
            <>
              {/* <p className="text-center text-white"> You have scheduled contents <br/> for {buttons[activeButton] == 'Twitter' ? 'Twitter/X' : buttons[activeButton]}  ({currentMonth || thisMonth} {currentYear || thisYear}).{" "}</p> */}

              {/* <p className="text-center text-white"> Scheduler AI has scheduled <br/> from {scheduleData?.find(item => item.platform === buttons[activeButton])?.startingDate} to {scheduleData?.find(item => item.platform === buttons[activeButton])?.endDate} </p> */}
            </>
          )}
      </div>

      {/* Scheduler button  */}
      {scheduleData?.length !== 0 &&
      Array.isArray(scheduleData) &&
      scheduleData?.some((item) => item.platform === buttons[activeButton]) ? (
        <button
          onClick={handleReSchedulerClick}
          className=" mb-4 mx-auto d-flex btn btn-secondary"
        >
          {" "}
          Re-Scheduler AI{" "}
        </button>
      ) : (
        <button
          onClick={handleSchedulerAIClick}
          className="ai-button rounded mx-auto d-flex"
        >
          {" "}
          Scheduler AI{" "}
        </button>
      )}

      {/* time & country  */}
      <div className="text-center text-white pb-0 mb-0">
        <p className="">
          {" "}
          {isChanging &&
          scheduleData?.find((item) => item.platform !== buttons[activeButton])
            ?.country ? (
            <>Country: Processing... </>
          ) : (
            <>
              {" "}
              {AIuser && !isChanging ? (
                <>Country: {userCountry || AIuser?.country}</>
              ) : (
                <>Country: Detecting...</>
              )}
            </>
          )}
          <button
            onClick={() => {
              setIsEditingCountry(true);
              setisChanging(true);
            }}
            className="edit-button"
          >
            {" "}
            Edit
          </button>
        </p>
        {/* Time  */}
        <p className="pb-0 mb-0">
          Daily Posting Time: {AIuser ? <span>{time}</span> : <>Detecting...</>}{" "}
          (24 hours)
          <button
            onClick={() => {
              if (
                !(
                  scheduleData?.length !== 0 &&
                  Array.isArray(scheduleData) &&
                  scheduleData?.some(
                    (item) => item.platform === buttons[activeButton]
                  )
                )
              ) {
                swal({
                  text: `Please adjust the timing of posting after scheduling the content.`,
                  icon: "warning",
                  // timer: 2000,
                  className: "modal_class_success",
                });
              } else {
                setIsEditingTime(true);
              }
            }}
            style={{
              backgroundColor: !(
                scheduleData?.length !== 0 &&
                Array.isArray(scheduleData) &&
                scheduleData?.some(
                  (item) => item.platform === buttons[activeButton]
                )
              )
                ? "#5C636A"
                : "#39ACEF",
            }}
            className="edit-button mb-0"
          >
            {" "}
            Edit{" "}
          </button>
        </p>
      </div>

      {/* last part of the page  */}
      <p className="server-sm">
        There may be slight variations in posting times due to factors such as
        server congestion.
      </p>
      {/* <div className="container mx-auto d-flex justify-content-center mx-4">
        <button 
        // style={{width: '500px',}}
        onClick={handleShowPlatform} className="btn mb-2 generating text-center rounded"> <span className="pb-0 pt-0 mt-0 mb-0 text-center">START GENERATING CONTENTS </span> <span className="pb-0 pt-0 mt-0 mb-0 text-center">AND POSTING AUTOMATICALLY</span>  </button>
      </div> */}
      <div
        className="text-center container mx-auto mb-2"
        // style={{width: '100%'}}
      >
        <button
          onClick={handleShowPlatform}
          style={{ width: "100%" }}
          className="btn btn-warning automatically"
        >
          <span>START GENERATING CONTENTS</span>
          <span> AND POSTING AUTOMATICALLY</span>
        </button>
      </div>
      <div className=" container mx-auto d-flex justify-content-center mx-4">
        <button
          onClick={() => {
            navigate("/analysis");
          }}
          className="btn btn-info analysis rounded"
        >
          <p className="pb-0 pt-0 mt-0 mb-0">VIEW YOUR ANALYSIS </p>{" "}
        </button>
      </div>

      {/* show Platform modal  */}
      <Modal show={showPlatforms} onHide={() => setShowPlatforms(false)}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>SMMM AI</Modal.Title>{" "}
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "#011225", color: "#fff" }}>
          <div className="mt-3">
            {buttons.map((platform, index) => (
              <p key={index} className="platform-select">
                <span className="platform-list py-0 px-0 my-0">
                  {platform == "Twitter" ? "Twitter/X" : platform}
                </span>
                <button
                  className=" btn btn-lg py-0 my-0"
                  onClick={() => {
                    if (
                      !scheduleData?.some((item) => item.platform === platform)
                    ) {
                      swal({
                        text: `Scheduling Not Done. Please do before activating.`,
                        icon: "warning",
                        className: "modal_class_success",
                      });
                    } else {
                      handleToggle(platform);
                    }
                  }}
                >
                  {!scheduleData?.some((item) => item.platform === platform) ? (
                    <>
                      {" "}
                      <MdOutlineToggleOff
                        className="p-0"
                        size={30}
                        style={{ color: "gray" }}
                      />{" "}
                    </>
                  ) : (
                    <>
                      {post?.includes(platform) ? (
                        <MdOutlineToggleOn
                          className="p-0"
                          size={30}
                          style={{ color: "green" }}
                        />
                      ) : (
                        <MdOutlineToggleOff
                          className="p-0"
                          size={30}
                          style={{ color: "red" }}
                        />
                      )}
                    </>
                  )}
                </button>
                {!toggledPlatforms.includes(platform) ? (
                  <p
                    onClick={() => {
                      setActiveButton(index);
                      setShowPlatforms(false);
                      if (
                        scheduleData?.find(
                          (item) => item.platform === buttons[activeButton]
                        )
                      ) {
                        setTime(
                          scheduleData?.find(
                            (item) => item.platform === buttons[activeButton]
                          )?.contentCategory[indexNumber]?.timeOfPosting
                        );
                      } else {
                        setTime("09:00");
                      }
                      window.scrollTo(0, 0);
                    }}
                    className="toggle-status-done py-0 my-0 "
                  >
                    Scheduling Not Done. <br /> Click here to do.
                  </p>
                ) : (
                  <p className="toggle-status py-0 my-0">Scheduling Done. </p>
                )}
              </p>
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-warning subscribe-button mx-auto"
            onClick={() => handlePay(post)}
          >
            {" "}
            {post?.length === 1 ? (
              <> Subscribe Monthly for USD 30.00</>
            ) : (
              <>
                {" "}
                {post?.length === 2 ? (
                  <> Subscribe Monthly for USD 48.00 </>
                ) : (
                  <>
                    {" "}
                    {post?.length === 3 ? (
                      <> Subscribe Monthly for USD 63.00 </>
                    ) : (
                      <>
                        {" "}
                        {post?.length === 4 ? (
                          <> Subscribe Monthly for USD 72.00 </>
                        ) : (
                          <> Subscribe Monthly for USD 00.00 </>
                        )}
                      </>
                    )}{" "}
                  </>
                )}{" "}
              </>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShowPlatforms(false);
              setToggledPlatforms([]);
              setPost([]);
            }}
          >
            {" "}
            Close{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* scheduler date info pop up modal  */}
      <Modal show={info} onHide={() => setInfo(false)}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body style={{ backgroundColor: "#011225", color: "#fff" }}>
          <div className="mt-3 text-center">
            <div className="mb-3 d-flex justify-content-center gap-5">
              <button
                onClick={() => {
                  setIsOwnUpload(false);
                }}
                className="btn btn-warning"
              >
                AI content
              </button>
              <button
                onClick={() => {
                  setIsOwnUpload(true);
                }}
                className="btn btn-primary"
              >
                Own content
              </button>
            </div>
            {/* <button className="btn btn-secondary px-4">
              {scheduleData?.find(
                (item) => item.platform === buttons[activeButton]
              )?.platform == "Twitter"
                ? "Twitter/X"
                : scheduleData?.find(
                    (item) => item.platform === buttons[activeButton]
                  )?.platform}
            </button> */}

            {isOwnUpload ? (
              <div>
                <form style={{ width: "100%", marginTop: "40px", marginBottom: '20px' }} className="">
                 

                  <div className="form-group">
                    {/* <p htmlFor="contentTextarea">Text with hashtags</p> */}
                    <textarea
                      className="form-control custom-textarea"
                      id="contentTextarea"
                      rows="2"
                      placeholder="What's on your mind"
                      style={{
                        backgroundColor: "#BDBDBD",
                        // color: "#fff",
                        color: 'black'
                        // border: "none",
                        // border: '',
                        // borderColor: "#fff",
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // alert('I am here');
                      }}
                      className="d-flex justify-content-start mt-2 align-items-center gap-2"
                    >
                      <FaVideo style={{ color: "red" }} /> Live Video
                    </div>

                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // alert('I am here');
                      }}
                      className="d-flex justify-content-start mt-2 align-items-center gap-2"
                    >
                      <IoMdPhotos style={{ color: "green" }} /> Photo / Video
                    </div>

                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // alert('I am here');
                      }}
                      className="d-flex justify-content-start mt-2 align-items-center gap-2"
                    >
                      <MdOutlineEmojiEmotions style={{ color: "yellow" }} />{" "}
                      Feeling / Activity
                    </div>
                  </div>

                  {/* <FaVideo/> */}

                  {/* <button
                type="submit"
                className="btn btn-primary mt-3"
                style={{ backgroundColor: "#0066cc", borderColor: "#005cbf" }}
              >
                Upload
              </button> */}
                </form>
              </div>
            ) : (
              <div>
                <button className="btn btn-secondary px-4">
                  {scheduleData?.find(
                    (item) => item.platform === buttons[activeButton]
                  )?.platform == "Twitter"
                    ? "Twitter/X"
                    : scheduleData?.find(
                        (item) => item.platform === buttons[activeButton]
                      )?.platform}
                </button>
                <p className="pt-2 pb-0 mb-0">Date:</p>
                <p className="pb-0 mb-0">
                  {new Date(selectedDate.getTime() + 86400000)
                    .toISOString()
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
                </p>
                <p className="pt-2 pb-0 mb-0">Time of posting:</p>
                <p className="py-0 my-0">
                  {todaysContent?.timeOfPosting} (24 hours)
                </p>
                <p
                  style={{ color: isDisabled ? "white" : "orange" }}
                  className="pt-2 pb-0 mb-0"
                >
                  Content:
                </p>
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <p
                    style={{ color: isDisabled ? "white" : "orange" }}
                    className="py-0 my-0"
                  >
                    {todaysContent?.content}
                  </p>
                  <div>
                    {isDisabled ? (
                      <FaArrowAltCircleDown
                        onClick={handleToggleInfo}
                        className="p-0"
                        size={20}
                        style={{ color: "gray" }}
                      />
                    ) : !contentToggle ? (
                      <FaArrowAltCircleDown
                        onClick={() => {
                          setContentToggle(true);
                        }}
                        className="p-0"
                        size={20}
                        style={{ color: "orange" }}
                      />
                    ) : (
                      <FaArrowAltCircleUp
                        onClick={() => {
                          setContentToggle(false);
                        }}
                        className="p-0"
                        size={20}
                        style={{ color: "orange" }}
                      />
                    )}
                  </div>
                </div>
                <p className="py-0 my-0"></p>
                <p className="py-0 my-0  ">
                  {isDisabled ? (
                    <MdOutlineToggleOff
                      onClick={handleToggleInfo}
                      className="p-0"
                      size={40}
                      style={{ color: "gray" }}
                    />
                  ) : infoToggole ? (
                    <MdOutlineToggleOff
                      className="p-0"
                      size={40}
                      style={{ color: "red" }}
                      onClick={handleToggleInfo}
                    />
                  ) : (
                    <MdOutlineToggleOn
                      className="p-0"
                      size={40}
                      style={{ color: "green" }}
                      onClick={handleToggleInfo}
                    />
                  )}
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-5">
            {!isDisabled && (
              <Button
                variant="info"
                onClick={() => {
                  setIsOwnUpload(true);
                }}
              >
                {" "}
                Upload own content
              </Button>
            )}
            <Button
              variant="secondary"
              style={{ marginLeft: "45px" }}
              className=""
              onClick={() => {
                setInfo(false);
              }}
            >
              {" "}
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* country selection modal  */}
      <Modal
        show={isEditingCountry}
        onHide={() => {
          setIsEditingCountry(false);
          setisChanging(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Country</Modal.Title>
        </Modal.Header>
        <p className="px-4 pt-0 pb-0">
          If you change country, it will change for all social media platforms
        </p>
        <Modal.Body>
          <Select
            options={countryOptions}
            value={countryOptions.find(
              (option) => option.label === userCountry
            )}
            onChange={handleCountryChange}
            styles={customStyles}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setisChanging(false);
              setIsEditingCountry(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* time editing modal  */}
      <Modal
        show={isEditingTime}
        onHide={() => {
          setIsEditingTime(false);
          if (hourUpdate || minUpdate) {
            //   Swal.fire({
            //   text: 'Time of posting updated successfully',
            //   color: 'white',
            //   background: '#011225'
            //  })
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Posting Time</Modal.Title>
        </Modal.Header>
        <p className="px-4 pt-0 pb-0 ">
          {" "}
          {`You can set different timings for each social media platform, which will take effect starting from ${tomorrowDate}.`}{" "}
        </p>
        <Modal.Body>
          <CustomTimePicker
            clearTimer={handleClearTimer}
            time={time}
            setTime={setTime}
            scheduleData={scheduleData}
            buttons={buttons}
            activeButton={activeButton}
            AIuser={AIuser}
            getScheduleByEmail={getScheduleByEmail}
            todaysContent={todaysContent}
            currentDateContent={currentDateContent}
            onHourUpdate={handleHourUpdate}
            onMinUpdate={handleMinUpdate}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsEditingTime(false);
              if (hourUpdate || minUpdate) {
                //   Swal.fire({
                //   text: 'Time of posting updated successfully',
                //   color: 'white',
                //   background: '#011225'
                //  })
              }
            }}
          >
            Set Time
          </Button>
        </Modal.Footer>
      </Modal>

      {/* unique content  */}
      <Modal show={contentToggle} onHide={() => setContentToggle(false)}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body style={{ backgroundColor: "#011225", color: "#fff" }}>
          <div className=" text-left my-0 py-0">
            <div className="my-1">
              {uniqueContent?.map((i, j) => (
                <div
                  className="d-flex justify-content-centre align-items-center my-1 gap-2"
                  key={j}
                >
                  <div
                    onClick={() => {
                      handleCheckboxClick(i);
                    }}
                    style={{ cursor: "pointer", fontSize: "16px" }}
                  >
                    {todaysContent?.content == i ? (
                      <MdOutlineCheckBox />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank />
                    )}
                  </div>
                  <div
                    style={{
                      background: buttonColors[j],
                      color: "white",
                      paddingLeft: "10px",
                    }}
                    className="btn schedule-info-unique pl-4 px-1 py-1 m-0 "
                  >
                    <span className="ml-2 text-left pl-4 content-unick">
                      {" "}
                      {i}
                    </span>
                    {/* onClick={() => handleInfoClick()} */}
                    <div className="text-right" style={{ cursor: "pointer" }}>
                      <FaInfoCircle onClick={() => handleShowSample(i)} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Modal show={showContentType !== ""} onHide={closeModal} centered>
            <Modal.Header
              style={{ backgroundColor: "#011225", color: "#fff" }}
              closeButton
            >
              <Modal.Title>{showContentType}</Modal.Title>
            </Modal.Header>
          </Modal>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setContentToggle(false);
            }}
          >
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal>

      {/* upload content modal  */}

      {/* <Modal show={isOwnUpload} onHide={() => setIsOwnUpload(false)}>
        <Modal.Header closeButton >
             <h5 className="fw-bold">Upload Your Own Content</h5>
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "#011225", color: "#fff" }}>
          <div className="text-left my-0 py-0">
            <form style={{width: '80%'}}>
              

              <div className="form-group my-3">
                <p htmlFor="fileUpload">Choose a file to upload</p>
                <input
                  type="file"
                  className="form-control-file"
                  id="fileUpload"
                  style={{
                    color: "#fff",
                    width: '100%',
                    padding: "10px",
                    border: "2px solid #fff", // Adds the border
                    borderRadius: "5px", // Rounds the edges
                    backgroundColor: "#011225", // Matches the background of the modal
                  }}
                />
              </div>

              <div className="form-group">
                <p htmlFor="contentTextarea">Text with hashtags</p>
                <textarea
                  className="form-control"
                  id="contentTextarea"
                  rows="3"
                  placeholder="Text with hashtags"
                  style={{
                    backgroundColor: "#011225",
                    color: "#fff",
                    borderColor: "#fff",
                  }}
                />
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-3"
                style={{ backgroundColor: "#0066cc", borderColor: "#005cbf" }}
              >
                Upload
              </button>
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setIsOwnUpload(false);
            }}
          >
            CLOSE
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default Calendars;
