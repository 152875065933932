import { Button } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import swal from 'sweetalert';
import { saveAs } from 'file-saver';
import axios from 'axios';

import { Select, Space, Spin } from 'antd';
import { DownloadOutlined } from '@mui/icons-material';
import { array, myArray, imageArray } from '../VideoAi/api';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import moneyExchangeIcon from '../../Assets/Images/transaction-data.png';
import { useLocation, useNavigate } from 'react-router-dom';
import PageHeading from '../../Components/PageHeading/PageHeading';

import reload from "../../Assets/Images/relaoder.png";
import SocialMediaPost from './SocialMediaPost';
import { FaRegCopy } from 'react-icons/fa';
import Swal from 'sweetalert2';

const GenerateImage = ({ data }) => {
  const { AIuser, userRefetch, setUserRefetch } = useContext(UserAuthContext);
  const randomIndex = Math.floor(Math.random() * imageArray.length);
  const textItem = imageArray[randomIndex];
  const [prompt, setPrompt] = useState(textItem);
  const [hashtag, setHashtag] = useState();
  const [generatedImage, setGeneratedImage] = useState(null);
  const [generatedImageOpenAI, setGeneratedImageOpenAI] = useState(null);
  const [imageSize, setImageSize] = useState('1024x1024');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [isImage, setIsImage] = useState(false);
  const imgRef = useRef();
  const navigate = useNavigate();
  const [rndmItem, setRndmItem] = useState(0);
  const [formData, setFormData] = useState({ description: '' });

  const handleChangeDescription = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(data , 'for image');

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the index to move to the next image
      setRndmItem((prevIndex) =>
        prevIndex === myArray?.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [myArray?.length]);

  const handleSubmitCredits = async (e) => {
    const deductedCredits = AIuser.credits - 20;

    // console.log("question", question, conversation);
    await axios
      .put(
        'https://backend.whalesai.com/api/dashboard/user_signup-signin/update-credits',
        { email: AIuser?.email, credits: deductedCredits },
        {
          headers: {
            authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserRefetch(!userRefetch);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageGenerate = async () => {
    if (AIuser.credits > 20) {
      const randomChars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655';
      let transactionId = '';
      for (let i = 0; i < 8; i++) {
        transactionId += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      setGeneratedImage(null);
      setGeneratedImageOpenAI(null)
      setIsLoading(true);
      setIsLoaded(true);

      console.log('random text : ' + rndmItem);

      await axios
        .post(
          'https://backend.whalesai.com/api/v1/chatgpt/imageai',
          { prompt, imageSize },
          {
            headers: {
              authorization: `Bearer dsltoken`,
            },
          }
        )
        .then(async (res) => {
          if (res.status === 200) {
            console.log('firstTime', firstTime);
            console.log(res?.data?.textwithHashTag);
            setHashtag(res?.data?.textwithHashTag);
            setGeneratedImageOpenAI(res?.data?.imageUrl);
            setGeneratedImage(res?.data?.imageUrl);
            setIsImage(false);
            handleSubmitCredits();
            await axios.post(
                        'https://backend.whalesai.com/api/v1/credits-transaction/create',
                        {
                          aiType: 'Image Ai',
                          transactionId: transactionId,
                          requestQues: prompt,
                          response: res?.data?.imageUrl,
                          credit: 20,
                          userId: AIuser?._id,
                          textwithHashTag: res?.data?.textwithHashTag,
                          imageAiSize: imageSize,

                        }
                      );
                    
                
            // if (firstTime == false) {
  

            //   console.log('firstTime insideee');

            //   await axios
            //     .post(
            //       'https://backend.dsl.sg/api/v1/chatgpt/download-image',
            //       { imageUrl: res.data.imageUrl },
            //       {
            //         headers: {
            //           authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
            //         },
            //       }
            //     )
            //     .then(async (res) => {
            //       console.log(res);
            //       if (res.status === 200) {
            //         setGeneratedImage(res?.data?.downloadUrl);
                    
            //         const transactionRes = await axios.post(
            //           'https://backend.whalesai.com/api/v1/credits-transaction/create',
            //           {
            //             aiType: 'Image Ai',
            //             transactionId: transactionId,
            //             requestQues: prompt,
            //             response: res?.data?.downloadUrl,
            //             credit: 20,
            //             userId: AIuser?._id,
            //             imageAiSize: imageSize,
            //           }
            //         );
            //       }
            //     });
            // }
            // else {
            //    setGeneratedImage(res?.data?.imageUrl);
            // }
            setFirstTime(false);
            // swal.close();
            setIsLoaded(false);
            setIsImage(true);
            console.log(res.data.imageUrl);
           
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      swal({
        text: "You don't enough credits! Please buy credits to continue.",

        icon: 'error',
        buttons: 'Ok',
        className: 'modal_class_success',
      }).then((value) => {
        if (value) {
          navigate('/purchase');
        }
      });
    }
  };

  const buttonRef = useRef(null);

  // const downloadImage = async () => {
  //   setDownloading(true);
  //   await axios
  //     .post(
  //       'https://backend.dsl.sg/api/v1/chatgpt/download-image',
  //       { imageUrl: generatedImage },
  //       {
  //         headers: {
  //           authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
  //         },
  //       }
  //     )
  //     .then(async (res) => {
  //       console.log('hello');
  //       if (res.status === 200) {
  //         console.log(res?.data?.downloadUrl, 'downloadUrl');
  //         try {
  //           const response = await axios.get(
  //             'https://cors-anywhere.herokuapp.com/' + res?.data?.downloadUrl,
  //             {
  //               responseType: 'blob',
  //               headers: {
  //                 'X-Requested-With': 'XMLHttpRequest', // Required header
  //               },
  //             }
  //           );
  //           // Create a new Blob object using the response data
  //           const blob = new Blob([response.data], { type: 'image/jpeg' });
  //           const blobUrl = URL.createObjectURL(blob);
  //           const newTab = window.open(blobUrl, '_blank');
  //           if (newTab) {
  //             newTab.onload = () => {
  //               URL.revokeObjectURL(blobUrl);
  //             };
  //           }
  //           // Save the image
  //           saveAs(blob, 'image.jpg');

  //           setDownloading(false);
  //         } catch (error) {
  //           console.error('Error downloading the image', error);
  //           setDownloading(false);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       setDownloading(false);
  //       console.log(err);
  //     });
  // };

  const handleChange = (value) => {
    setImageSize(value);
  };

  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";

  useEffect(() => {
    setFirstTime(false)
    if (location.state !== null) {
      console.log(location.state.response);
      setPrompt(location.state.requestQues);
      setGeneratedImage(location.state.response);
      setIsImage(false);
      setFirstTime(false);
      setImageSize(location.state.imageAiSize);
      setHashtag(location.state.textwithHashTag);
      console.log('location', location.state.textwithHashTag);
      // setIsLoading(false);
    }
  }, [location.state]);


  const handleCopy = () => {
    navigator.clipboard.writeText(hashtag)
      .then(() => {
        // Show SweetAlert on success
        swal({
          icon: 'success',
          // title: 'Copied!',
          text: 'The Text with HashTag has been copied to your clipboard.',
          // timer: 1500,
          showConfirmButton: false,
          className: "modal_class_success",
        });
      })
      .catch(err => {
        // Show SweetAlert on error
        swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          className: "modal_class_success",
        });
      });
  };

  return (
    <>
      <PageHeading title={'IMAGE AI'} description={'The Image AI assists in crafting images tailored for social media platforms.'}/>
      <div className="container img-ai-container">
        <div
          // style={{ maxWidth: '1025.33px', marginInline: 'auto' }}
          style={{
            maxWidth: "872px",
            paddingInline: "12px",
            marginInline: "auto",
          }}
          // className="CreditDiv mt-2"
          className="d-flex align-items-center justify-content-center centralize-credits-btn-sm mt-2"
        >
          <div className=''>
            <div className="">
              <button className="credits-button">
                CREDITS : {creditTolcalString}
              </button>
              <button
                className="buy-credits-button"
                onClick={() => navigate('/purchase')}
              >
                BUY CREDITS
              </button>

              <button
                onClick={() => navigate('/transactions')}
                className="money-exchange-button"
              >
                <img src={moneyExchangeIcon} alt="image" />
              </button>
            </div>
            <p className="text-white mt-2 creditText text-center">
              20 Credits per image 
            </p>
          </div>
        </div>
        <div
          // style={{ maxWidth: '1025.33px', marginInline: 'auto' }}
          style={{
            maxWidth: "872px",
            paddingInline: "12px",
            marginInline: "auto",
          }}
          className="pt-3 "
        >
          <div>
            <div className='text-center mb-3'>
            <img                 onClick={() => {
                  const randomIndex = Math.floor(
                    Math.random() * imageArray.length
                  );
                  if (!(prompt == imageArray[randomIndex])) {
                    setPrompt(imageArray[randomIndex]);
                  } else {
                    setPrompt(
                      imageArray[Math.floor(Math.random() * imageArray.length)]
                    );
                  }
                }} style={{cursor:"pointer"}} src={reload} alt="reload" width="30" height="30"/>
              {/* <i
                class="fas fa-sync-alt"
                style={{ cursor: 'pointer', color: 'white' }}
                onClick={() => {
                  const randomIndex = Math.floor(
                    Math.random() * imageArray.length
                  );
                  if (!(prompt == imageArray[randomIndex])) {
                    setPrompt(imageArray[randomIndex]);
                  } else {
                    setPrompt(
                      imageArray[Math.floor(Math.random() * imageArray.length)]
                    );
                  }
                }}
            ></i> */}
            </div>
            <div className="d-flex flex-column-reverse flex-md-row align-items-center align-items-md-start gap-2">
              <textarea
                onChange={(e) => setPrompt(e.target.value)}
                className="generate-text-input p-1 bg-white text-dark mb-2"
                value={prompt ? prompt : ''}
                type="text"
                name=""
                id=""
                rows="5"
              />
              {/* <i
                class="fas fa-sync-alt"
                style={{ cursor: 'pointer', color: 'white' }}
                onClick={() => {
                  const randomIndex = Math.floor(
                    Math.random() * imageArray.length
                  );
                  if (!(prompt == imageArray[randomIndex])) {
                    setPrompt(imageArray[randomIndex]);
                  } else {
                    setPrompt(
                      imageArray[Math.floor(Math.random() * imageArray.length)]
                    );
                  }
                }}
              ></i> */}
            </div>
            <div className="d-flex flex-column-reverse flex-md-row align-items-center align-items-md-start gap-2">
              <Select
                className="size-select"
                defaultValue={imageSize}
                style={{
                  width: '50%',
                  textAlign: 'start',
                }}
                placeholder="Select a size"
                onChange={handleChange}
                options={[
                  {
                    value: '1024x1024',
                    label: '1024x1024',
                  },
                  {
                    value: '1792x1024',
                    label: '1792x1024',
                  },
                  {
                    value: '1024x1792',
                    label: '1024x1792',
                  },
                  
                ]}
              />
              {/* <i
                className="fas fa-sync-alt d-none d-md-flex"
                style={{ visibility: 'hidden' }}
              ></i> */}
            </div>

            <p className="pt-1 pb-2 text-white mb-0 text-center text-md-start">
              {/* Every request is 50 credits. */}
            </p>

            <div className="d-flex justify-content-center align-items-center">
              <Button
                sx={{
                  padding: '8px 30px',
                  marginRight: '10px',
                  color: '#000',
                  background: '#fff',
                  '&:hover': { background: '#fff' },
                  '&:disabled': { background: '#fff' },
                }}
                onClick={() => setPrompt('')}
                disabled={!prompt}
                variant="contained"
              >
                Clear
              </Button>
              <Button
                sx={{
                  padding: '8px 30px',
                  margin: '15px 0px',
                  '&:disabled': { background: '#000' },
                }}
                ref={buttonRef}
                onClick={() => {
                  imageGenerate();
                }}
                className={`generate-button text-white `}
                disabled={!prompt || (isLoading && isImage) }
                variant="contained"
              >
                {isLoaded ? (
                  // <span className="typewriter">Processing...</span>
                  'Processing...'
                ) : (
                  'Generate'
                )}
              </Button>
            </div>
            {/* {
              isLoaded && <div className='animate text-md-center mt-4 text-lg-center text-center'>Please do not navigate away from the browser. Doing so will stop the process.</div>
            } */}
            
            <div className='animate text-md-center mt-4 text-lg-center text-center mb-5 d-flex justify-content-center'>Please do not navigate away from the browser.<br/> Doing so will stop the process.</div>
            
          </div>
          {generatedImage && (
            <div className="text-center mb-3">
              {isLoading && <Spin size="large" className="pt-5 pb-2 " />}
              {isLoading && (
                <p className="text-center pb-2 d-block text-white mb-0">
                  {myArray[rndmItem]}
                </p>
              )}

              <div
                style={{
                  display: isLoading ? 'none' : 'block',
                }}
              >
                <div ref={imgRef}>
                  <img
                    className="border img-fluid mx-auto"
                    src={generatedImageOpenAI  || location?.state?.response}
                    alt="image"
                    onLoad={() => setIsLoading(false)}
                    style={{
                      display: isLoading ? 'none' : 'block',
                    }}
                  />
                </div>
                <form className="form-container">
                  <div className="form-item">
                    {/* <label htmlFor="description">Description</label> */}
                    <textarea
                      className="generate-text-input mt-3 p-1 bg-white text-dark mb-2"
                      id="description"
                      name="description"
                      // value={prompt}
                      value={`${hashtag || location.state.textwithHashTag}`}
                      onChange={handleChangeDescription}
                      // className="textarea-box"
                    />
                  </div>
                  
                </form>
                <button
                onClick={handleCopy}
                className="d-flex mx-auto justify-content-center align-items-center px-4 py-2 gap-2 text-white btn btn-success"
                    style={{ width: '110px', borderRadius: '5px', fontSize: '16px', color: 'black' }}
                    >
                      Copy
                      <FaRegCopy />
                </button>
                {/* <p className='text-white text-center'>{generatedImage}</p> */}
                {/* <SocialMediaPost img={generatedImage}/> */}
                {/* <Button
                  sx={{
                    padding: '8px 30px',
                    margin: '15px auto',
                    display: 'block',
                    background: '#2e7d32!important',
                    color: 'white!important',
                  }}
                  variant="contained"
                  onClick={downloadImage}
                  disabled={downloading}
                >
                  <DownloadOutlined />
                  {downloading ? 'Downloading...' : 'Download'}
                </Button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenerateImage;
