
import axios from "axios";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const CustomTimePicker = ({ time, setTime, clearTimer, scheduleData, buttons, activeButton, getScheduleByEmail, AIuser, todaysContent, currentDateContent, onHourUpdate, onMinUpdate  }) => {
  // Parse the initial time prop
  const initialHour = parseInt(time.split(":")[0]);
  const initialMinute = parseInt(time.split(":")[1]);

  const [showHours, setShowHours] = useState(false);
  const [showMinutes, setShowMinutes] = useState(false);
  const [selectedHour, setSelectedHour] = useState(initialHour);
  const [selectedMinute, setSelectedMinute] = useState(initialMinute);
  const [hourUpdate, setHourUpdate] = useState(false);
  const [minUpdate, setMinUpdate] = useState(false);

  const toggleHoursDropdown = () => {
    setShowHours(!showHours);
    setShowMinutes(false); // Close minutes dropdown when opening hours
  };

  const toggleMinutesDropdown = () => {
    setShowMinutes(!showMinutes);
    setShowHours(false); // Close hours dropdown when opening minutes
  };
  // Define the API endpoint
  const API_ENDPOINT = 'https://backend.whalesai.com/api/v1/social-schedule/schedules/time/';
  const API_ENDPOINT2 = 'https://backend.whalesai.com/api/v1/social-schedule/schedules/'

  const handleHourSelect = async (hour) => {
    setSelectedHour(hour);
    const newTime = `${hour.toString()?.padStart(2, "0")}:${selectedMinute?.toString().padStart(2, "0")}`;
    setTime(newTime);
    clearTimer();
    setShowHours(false);
    console.log(currentDateContent?._id);

    // Prepare the data to be sent in the request
    if (currentDateContent) {
      const activeSchedule = scheduleData?.find(item => item.platform === buttons[activeButton]);
      console.log('ajke', activeSchedule);
      const requestData = {
        // ...activeSchedule,
        contentCategoryId: currentDateContent?._id,
        newTimeOfPosting: newTime
      };

      const anotherData = {
        timeOfPosting: newTime
      }

      try {
        const updatMain = await axios.put(`${API_ENDPOINT}${activeSchedule?._id}`, anotherData);
        const response = await axios.put(`${API_ENDPOINT}${activeSchedule?._id}`, requestData);
        console.log('Time of posting updated successfully:', response.data, updatMain.data);
        // Swal.fire({
        //   text: 'Time of posting updated successfully',
        //   color: 'white',
        //   background: '#011225'
        // })
        setHourUpdate(true);
        onHourUpdate(true);
        await getScheduleByEmail(AIuser?.email);
      } catch (error) {
        console.error('Error updating time of posting:', error);
        setHourUpdate(false);
        onHourUpdate(false);
      }
    }
  };

  const handleMinuteSelect = async (minute) => {
    // Set the minute
    setSelectedMinute(minute);
  
    // Construct the new time directly
    const newTime = `${selectedHour?.toString()?.padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
    
    // Set the time using the new value
    setTime(newTime);
    console.log('min time', newTime);
  
    // Clear the timer and close the minutes selector
    clearTimer();
    setShowMinutes(false);
  
    if (currentDateContent) {
      const activeSchedule = scheduleData?.find(item => item.platform === buttons[activeButton]);
      const requestData = {
        contentCategoryId: currentDateContent?._id,
        newTimeOfPosting: newTime
      };
  
      try {
        // Update the schedule with the new time
        const updateResponse = await axios.put(`${API_ENDPOINT}${activeSchedule?._id}`, requestData);
        console.log('Time of posting updated successfully:', updateResponse.data);

        setMinUpdate(true);
        onMinUpdate(true);
        // Refresh the schedule data after the update
        await getScheduleByEmail(AIuser?.email);
      } catch (error) {
        console.error('Error updating time of posting:', error);
        setMinUpdate(false);
        onMinUpdate(false);
      }
    }
  };
  
  console.log('time on custom picker', time);

  const renderHoursDropdown = () => {
    const hours = Array.from({ length: 24 }, (_, index) => index);
    return (
      <div className="dropdown">
        <button className="dropdown-toggle p-2" onClick={toggleHoursDropdown} style={{ backgroundColor: "#011225", color: "white" }}>
          {selectedHour.toString().padStart(2, "0")}
        </button>
        {showHours && (
          <div className="dropdown-menu" style={{ display: "block", backgroundColor: "#011225", maxHeight: "23em", overflowY: "scroll" }}>
            {hours.map((h) => (
              <button
                key={h}
                className={`dropdown-item ${h === selectedHour ? "active" : ""}`}
                onClick={() => handleHourSelect(h)}
                style={{ backgroundColor: h === selectedHour ? "#5167f5" : "transparent", color: h === selectedHour ? "white" : "black" }}
              >
                {h.toString().padStart(2, "0")}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderMinutesDropdown = () => {
    const minutes = Array.from({ length: 60 }, (_, index) => index);
    return (
      <div className="dropdown">
        <button className="dropdown-toggle p-2" onClick={toggleMinutesDropdown} style={{ backgroundColor: "#011225", color: "white" }}>
          {selectedMinute.toString().padStart(2, "0")}
        </button>
        {showMinutes && (
          <div className="dropdown-menu" style={{ display: "block", backgroundColor: "#011225", maxHeight: "23em", overflowY: "scroll" }}>
            {minutes.map((m) => (
              <button
                key={m}
                className={`dropdown-item ${m === selectedMinute ? "active" : ""}`}
                onClick={() => handleMinuteSelect(m)}
                style={{ backgroundColor: m === selectedMinute ? "#5167f5" : "transparent", color: m === selectedMinute ? "white" : "black" }}
              >
                {m.toString().padStart(2, "0")}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="custom-time-picker d-flex justify-content-center align-items-center gap-2">
      {renderHoursDropdown()}
      <span>:</span>
      {renderMinutesDropdown()}
      <span className="fw-bold">(24 hours)</span>
    </div>
  );
};

export default CustomTimePicker;
