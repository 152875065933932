// import React from "react";
// import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import { makeStyles } from "@material-ui/core";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const HowItWorksTab = ({ tabValue, handleChangeTab }) => {
//   const useStyles = makeStyles((theme) => ({
//     customStyleOnTab: {
//       fontSize: "16px",
//       "@media (max-width: 768px)": {
//         fontSize: "12px",
//       },
//     },
//   }));

//   const classes = useStyles();

//   return (
//     <Box
//       sx={{
//         width: "100%",
//       }}
//     >
//       <Box
//         sx={{
//           borderBottom: 1,
//           borderColor: "divider",
//           width: "100%",
//           display: "flex",
//           justifyContent: "center",
//         }}
//       >
//         <Tabs
//           value={tabValue}
//           onChange={handleChangeTab}
//           variant="scrollable"
//           scrollButtons="auto"
//           aria-label="scrollable auto tabs example"
//         >
//           <Tab
//             label={
//               <span className={classes.customStyleOnTab}>CONSULTANT AI</span>
//             }
//             {...a11yProps(0)}
//           />
//           <Tab
//             label={<span className={classes.customStyleOnTab}>TEXT AI</span>}
//             {...a11yProps(1)}
//           />
//           <Tab
//             label={<span className={classes.customStyleOnTab}>IMAGE AI</span>}
//             {...a11yProps(2)}
//           />
//           <Tab
//             label={<span className={classes.customStyleOnTab}>POST AI</span>}
//             {...a11yProps(3)}
//           />
//           <Tab
//             label={
//               <span className={classes.customStyleOnTab}>TEXT VIDEO AI</span>
//             }
//             {...a11yProps(4)}
//           />
//           <Tab
//             label={
//               <span className={classes.customStyleOnTab}>NEWS VIDEO AI</span>
//             }
//             {...a11yProps(5)}
//           />
//           <Tab
//             label={
//               <span className={classes.customStyleOnTab}>COMIC VIDEO AI</span>
//             }
//             {...a11yProps(6)}
//           />
//         </Tabs>
//       </Box>
//     </Box>
//   );
// };

// export default HowItWorksTab;



import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { makeStyles } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  customStyleOnTab: {
    fontSize: "16px",
    "@media (max-width: 768px)": {
      fontSize: "12px",
    },
  },
}));

const HowItWorksTab = ({ tabValue, handleChangeTab }) => {
  const classes = useStyles();

  const ScrollButton = ({ direction, onClick }) => (
    <button
      onClick={onClick}
      style={{
        border: "none",
        background: "none",
        cursor: "pointer",
        padding: "0 8px",
        alignSelf: "center",
        color: '#3080AC',
      }}
    >
      {direction === "left" ? (
      <ArrowLeftIcon style={{ fontSize: "2rem" }} />
    ) : (
      <ArrowRightIcon style={{ fontSize: "2rem" }} />
    )}
    </button>
  );

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          ScrollButtonComponent={ScrollButton}
        >
          <Tab
            label={<span className={classes.customStyleOnTab}>CONSULTANT AI</span>}
            {...a11yProps(0)}
          />
          <Tab
            label={<span className={classes.customStyleOnTab}>TEXT AI</span>}
            {...a11yProps(1)}
          />
          <Tab
            label={<span className={classes.customStyleOnTab}>IMAGE AI</span>}
            {...a11yProps(2)}
          />
          <Tab
            label={<span className={classes.customStyleOnTab}>POST AI</span>}
            {...a11yProps(3)}
          />
          <Tab
            label={<span className={classes.customStyleOnTab}>TEXT VIDEO AI</span>}
            {...a11yProps(4)}
          />
          <Tab
            label={<span className={classes.customStyleOnTab}>COMIC VIDEO AI</span>}
            {...a11yProps(6)}
          />
          <Tab
            label={<span className={classes.customStyleOnTab}>NEWS VIDEO AI</span>}
            {...a11yProps(5)}
          />
          
        </Tabs>
      </Box>
    </Box>
  );
};

export default HowItWorksTab;
