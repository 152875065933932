import React from "react";
import { FaSearch } from "react-icons/fa";

function SearchBar({ handleSearch }) {
  return (
    <form className="mb-2">
      
      <div className="position-relative">
        <div className="position-absolute top-50 translate-middle-y start-0 ps-3">
          
          <FaSearch style={{color: 'red'}}/>
        </div>
        <input
          className="form-control ps-5"
          placeholder="Search"
          type="text"
          style={{width: '40%'}}
          onChange={(e) => handleSearch(e)}
          required
        />
      </div>
    </form>
  );
}

export default SearchBar;
