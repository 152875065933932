import axios from "axios";
import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RiAdminFill } from "react-icons/ri";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import swal from "sweetalert";
import { Button, Container, Form } from "react-bootstrap";
import "./DashboardModalNewAdmin.css";
import Swal from "sweetalert2";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

const DashboardModalNewAdmin = (props) => {
  const { setModalShowNewAdmin, setIsLoadingAdmin, refetch, setRefetch } =
    props;
  const [value, setValue] = useState();
  const [invalidPhoneError, setInvalidPhoneNumber] = useState(false);
  const { getAllAdmin, allAIAdmin } = useContext(AdminAuthContext);
  const [loading, setLoading] = useState(false);
  console.log("Now Totall Admin =====> ", allAIAdmin);

  const subNewAdmin = async (event) => {
    event.preventDefault();

    if (value === undefined || value.length <= 6) {
      setInvalidPhoneNumber(true);
      return;
    } else {
      setInvalidPhoneNumber(false);
    }

    const image = event.target.image.files[0];
    const name = event.target.name.value;
    const username = event.target.username.value;
    const phone = value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const confirmPassword = event.target.confirmPassword.value;

    const formDataAddAdmin = new FormData();
    formDataAddAdmin.append("name", name);
    formDataAddAdmin.append("username", username);
    formDataAddAdmin.append("email", email);
    formDataAddAdmin.append("phone", phone);
    formDataAddAdmin.append("image", image);
    formDataAddAdmin.append("password", password);

    if (password !== confirmPassword) {
      return swal({
        title: "Attention",
        text: "Confirm Password not match!",
        icon: "warning",
        button: "OK!",
        customClass: "modal_class_success",
        background: "#011225",
        backgroundColor: "#011225",
      });
      setLoading(false);
    } else {
      setLoading(true);
      await axios
        .post("https://backend.whalesai.com/api/v1/admin", formDataAddAdmin)
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            setModalShowNewAdmin(false);
            console.log(
              "From the response after Admin create",
              res?.data?.message
            );
            setRefetch(!refetch);
            event.target.reset();
            getAllAdmin();
            Swal.fire({
              text: "Admin created successfully.",
              icon: "success",
              button: "OK!",
              customClass: "modal_class_success",
              background: "#011225",
            });
            setLoading(false);
            // swal({
            //   // title: "Success",
            //   text: res.data.message,
            //   icon: "success",
            //   button: "OK!",
            //   customClass: "modal_class_success",
            //   background: "#011225",
            //   backgroundColor: "#011225"
            // });
          }
        })
        .catch((error) => {
          getAllAdmin();
          props.onHide();
          setLoading(false);
          swal({
            title: "Attention",
            text: error.response.data.message,
            icon: "warning",
            button: "OK!",
            customClass: "modal_class_success",
            background: "#011225",
            backgroundColor: "#011225",
          });
        });
    }
  };



  // if (loading) {
  //   props.onHide();
  //   return (
  //     <>
  //       {/* <div className="overlay"></div> */}
  //       <div className="custom-spinner"></div>
  //     </>
  //   );
  // }

  return (
    <Modal {...props} centered style={{ zIndex: 1000000 }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <RiAdminFill className="fs-4 uppercase" /> ADD ADMIN
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#303651", color: "white" }}>
        <Container>
          <Form onSubmit={subNewAdmin}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                name="image"
                required
              />
            </Form.Group>

            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                placeholder="full name"
                type="text"
                name="name"
                required
              />
            </Form.Group>

            <Form.Group controlId="username" className="mb-3">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                placeholder="username"
                type="text"
                name="username"
                style={{ textTransform: "lowercase" }}
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="email"
                type="email"
                name="email"
                required
              />
            </Form.Group>

            <Form.Group controlId="phone" className="mb-3">
              <Form.Label>Phone</Form.Label>
              <PhoneInput
                international
                defaultCountry="SG"
                countryCallingCodeEditable={true}
                className="form-control"
                type="text"
                value={value}
                onChange={setValue}
                required
                inputProps={{
                  name: "phone",
                  required: true,
                }}
              />
              {invalidPhoneError && (
                <Form.Text className="text-danger">
                  *Please enter a valid phone number
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                placeholder="password"
                type="password"
                name="password"
                required
              />
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="mb-3">
              <Form.Label>confirm Password</Form.Label>
              <Form.Control
                placeholder="confirm password"
                type="password"
                name="confirmPassword"
                required
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={props.onHide}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Add
              </Button>
            </Modal.Footer>
          </Form>
          {/* {loading && <div className="custom-spinner"></div>} */}
          {/* {
            loading && (
              <>
                <div className="overlay"></div>
                <div className="custom-spinner"></div>
              </>
            );
          } */}
          {
            loading && <div>
              {/* <div className="overlay"></div> */}
              <div className="custom-spinner"></div>
            </div>
          }
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardModalNewAdmin;
