// src/assets/AdminAuthContext.jsx
import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AdminAuthContext = createContext(null);

const AdminAuthProvider = ({ children }) => {
    const [allAIAdmin, setAllAIAdmin] = useState();
    const [currentAIAdmin, setCurrentAIAdmin] = useState();
    const [allAIUsers, setAllAIUsers] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const AdminToken = localStorage?.getItem('AiAdmin');
    // const navigate = useNavigate();

    // const adminSignIn = (email, password) => {
    //     setLoading(true);
    //     return signInWithEmailAndPassword(auth, email, password);
    // };

    // const adminLogOut = () => {
    //     setLoading(true);
    //     return signOut(auth);
    // };

    // useEffect(() => {
    //     const unsubscribe = onAuthStateChanged(auth, (currentAIAdmin) => {
    //         setAdminUser(currentAIAdmin);
    //         setLoading(false);
    //     });
    //     return () => unsubscribe();
    // }, []

    // Fetch all users
    useEffect(() => {
        fetch(`https://backend.whalesai.com/api/v1/admin`)
            .then((res) => res.json())
            .then((data) => setAllAIAdmin(data));
    }, []);

    const getAllAdmin = async () => {
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/v1/admin');
        setAllAIAdmin(response.data); 
        setIsLoading(false);
    };

    const getAllUser = async () => {
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/dashboard/user_signup-signin');
        console.log('getting user from context ', response?.data?.result);
        setAllAIUsers(response?.data?.result); 
        setIsLoading(false);
    };

    useEffect(() => {
        if (AdminToken) {
            getCurentAdmin();
        } else {
            console.error("Admin token is missing or invalid");
            // Optionally, redirect to login or refresh token logic
        }
    }, [AdminToken]);

    useEffect(() => {
        getAllUser();
    } ,[]);

    // useEffect( () => {
    //     fetch('https://backend.whalesai.com/api/dashboard/user_signup-signin').then(res => res.json()).then(data => {
    //         setAllAIUsers(data.result);
    //     })
    // } ,[])
    
    const getCurentAdmin = async () => {
        setIsLoading(true);    
        try {
            const response = await axios.get('https://backend.whalesai.com/api/v1/admin/admin', {
                headers: {
                    Authorization: `Bearer ${AdminToken}`, 
                },
            });
            console.log('From fetching Current Admin ====>>>>', response);
            setCurrentAIAdmin(response?.data?.admin);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized: Invalid or expired token");
                // Optionally, handle token expiration (e.g., logout or refresh token)
            } else {
                console.error("Error fetching current admin:", error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect( () => {
        if(!currentAIAdmin && !AdminToken) {
            localStorage.removeItem('AiAdmin');
            // navigate('/admin/login');
        }
    },[])
    

    console.log('current admin from Admin Context ======>>>>>', currentAIAdmin, AdminToken);
    



    console.log('Total user from context ====>>>>>', allAIUsers?.length);



    const value = {
       allAIAdmin,
       allAIUsers,
       currentAIAdmin,
       setCurrentAIAdmin,
       isLoading,
       AdminToken,
       getAllAdmin,
       getCurentAdmin,
       getAllUser,
    };

    return (
        <AdminAuthContext.Provider value={value}>
            { children}
            {/* !loading && */}
        </AdminAuthContext.Provider>
    );
};

export default AdminAuthProvider;
