import React, { useContext, useState } from 'react';
import logo from "../../Assets/Images/de-profile.jpg";
import { Link } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { AdminAuthContext } from '../../Contexts/AdminAuthContext';
import CustomPagination from '../../Components/Pagination/CustomPagination';
import usePagination from '../../hooks/UsePagination';
import './index.css'
import SearchBar from '../AdminUser/SearchBar';
import SpecificUser from './SpecificUser';
import Swal from 'sweetalert2';
import Spineer from '../../Components/CustomSpineer/Spineer';

const AdminGeneralUser = () => {
    const {allAIUsers, getAllUser} = useContext(AdminAuthContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [oneUser, setOneUser] = useState();
    const [modalOpen, setModalOpen] = useState(false);

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const filteredAdmins = allAIUsers?.filter((admin) =>
    admin?.name?.toLowerCase()?.includes(searchQuery)
  );
  let count = Math.ceil(filteredAdmins?.length / PER_PAGE);
  let perPageData = usePagination(filteredAdmins, PER_PAGE);
  

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const handleOpen = (user) => {
    // alert(user?._id);
    setOneUser(user);
    setModalOpen(true);
  }

  const handleuserDelete = (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this user?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://backend.whalesai.com/api/dashboard/user_signup-signin/${id}`, {
          method: "DELETE",
          headers: {
            // Authorization: `Bearer ${AdminToken}`,
          },
        })
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
              getAllUser();
              Swal.fire({
                html: `<p class='text-break text-white'>User Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else {
              getAllUser();
              Swal.fire({
                text: "Failed to delete the user.",
                icon: "error",
                background: "#011225",
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            getAllUser();
            Swal.fire({
              text: "An error occurred while deleting the user.",
              icon: "error",
              background: "#011225",
            });
          });
      }
    });
  };

    return (
        <div className="container-fluid p-4 text-white">
        <SpecificUser setModalOpen={setModalOpen} modalOpen={modalOpen} oneUser={oneUser} />
      <h5 className="text-start mb-4 font-weight-bold">USERS </h5>
      <SearchBar handleSearch={handleSearch} />
      {/* <SearchBar handleSearch={() => { }} /> */}
      <div className="pt-5 rounded my-2 mb-5 pb-3" style={{ backgroundColor: "#3080AC" }}>
        {/* <div className="mx-3">
          <div className="d-flex justify-content-end mb-3 mr-3">
            <button
              onClick={() => setModalShowNewAdmin(true)}
              className="btn btn-primary  "
            >
              ADD NEW ADMIN
            </button>
          </div>
        </div> */}
        <div className='my-3'></div>
        <div className="table-responsive overflow-auto">
          <table className="table table-hover"
          style={{ backgroundColor: "#3080AC" }}
          >
            <thead className="bg-[#1a1c339a] table-text-size">
              <tr>
                <th scope="col">S/no</th>
                {/* <th scope="col" className="d-none d-md-table-cell">
                  Image
                </th> */}
                <th scope="col">Username</th>
                <th scope="col" className="d-none d-md-table-cell">
                  Email
                </th>
                {/* <th scope="col" className="d-none d-md-table-cell">
                  Mobile
                </th> */}
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='table-text-size' style={{ backgroundColor: "#3080AC" }}>
              {
                allAIUsers ?  (
                  perPageData?.currentData()?.map((admin, index) => (
                    <tr key={admin._id}>
                      <td className='py-4 px-4'>{ (page-1)*10 + index + 1}</td>
                      
                      <td className="py-4" style={{ textTransform: "lowercase" }}>
                        {admin.name}
                      </td>
                      <td className="py-4 d-none d-md-table-cell">{admin.email}</td>
                      {/* <td className="d-none py-4 d-md-table-cell">{admin.phone}</td> */}
                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => {
                              handleOpen(admin);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                            // style={{borderRadius: '30px'}}
                            // to={`/admin/adminprofile/${admin._id}`}
                            // to='/admin/adminprofile'
                          >
                            <RemoveRedEyeIcon className='action-icon' />
                          </Link>
                          <button
                            className="btn rounded-circle btn-sm icon-body btn-danger"
                            onClick={() => handleuserDelete(admin._id)}
                          >
                            <Delete className='action-icon' />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                  <td
                    className="text-center"
                    colSpan={window.innerWidth <= 768 ? "4" : "6"}
                  >
                    <Spineer />
                  </td>
                </tr>
                )
              }
              {/* {perPageData?.currentData()?.map((admin, index) => (
                <tr key={admin._id}>
                  <td className='py-4 px-4'>{ (page-1)*10 + index + 1}</td>
                  
                  <td className="py-4" style={{ textTransform: "lowercase" }}>
                    {admin.name}
                  </td>
                  <td className="py-4 d-none d-md-table-cell">{admin.email}</td>
                  <td className="text-center">
                    <div className="btn-group gap-2" role="group">
                      <Link
                        onClick={() => {
                          handleOpen(admin);
                        }}
                        className="btn rounded-circle btn-sm icon-body btn-success"
                       
                      >
                        <RemoveRedEyeIcon className='action-icon' />
                      </Link>
                      <button
                        className="btn rounded-circle btn-sm icon-body btn-danger"
                        onClick={() => handleuserDelete(admin._id)}
                      >
                        <Delete className='action-icon' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>
          
        </div>
        <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          />
        
      </div>

      {/* <DashboardModalNewAdmin
        show={modalShowNewAdmin}
        refetch={() => {}}
        setRefetch={() => {}}
        setModalShowNewAdmin={setModalShowNewAdmin}
        onHide={() => setModalShowNewAdmin(false)}
        allAdmin={allAdmin}
        setAllAdmin={setAllAdmin}
      /> */}
    </div>
    );
};

export default AdminGeneralUser;