// import React, { useContext, useEffect, useState } from "react";
// import { FaCopy, FaEdit } from "react-icons/fa";
// import { MdAutoDelete } from "react-icons/md";
// import Swal from "sweetalert2";
// import { UserAuthContext } from "../../Contexts/UserAuthContext";
// import './ApiSettings.css'

// const Token = () => {
//   const [token, setToken] = useState(null);
//   const [keyName, setKeyName] = useState("");
//   const [tokensData, setTokensData] = useState([]);
//   const { AIuser } = useContext(UserAuthContext);

//   useEffect(() => {
//     const userToken = localStorage.getItem("aiUser");
//     if (userToken) {
//       setToken(userToken);
//       console.log("Token set from localStorage:", userToken);
//     } else {
//       console.error("No token found in localStorage");
//     }
//   }, []);

//   useEffect(() => {
//     setTokensData(AIuser?.tokens || []);
//   }, [AIuser]);

//   const handleInputChange = (event) => {
//     setKeyName(event.target.value);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
  
//     const userToken = localStorage.getItem("aiUser");
//     if (!userToken) {
//       console.error("No token found");
//       return;
//     }
  
//     const requestBody = {
//       keyName: keyName,
//     };
  
//     try {
//       const response = await fetch(
//         "https://backend.whalesai.com/api/dashboard/user_signup-signin/generate-token",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${userToken}`,
//           },
//           body: JSON.stringify(requestBody),
//         }
//       );
  
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
  
//       const data = await response.json();
//       console.log("Token generated successfully:", data);
//       Swal.fire({
//         title: "Success!",
//         text: "Your token has been Generated Successfully.",
//         icon: "success",
//         confirmButtonColor: "#3085d6",
//         background: "#011225",
//         color: "white",
//       });
  
//       // Update tokensData state with the new token
//       setTokensData((prevTokens) => [...prevTokens, data]);
//       setKeyName("");
//     } catch (error) {
//       console.log("There was a problem with the fetch operation:", error);
//       Swal.fire({
//         title: "Error!",
//         text: `There was a problem Generating your token. \n\n Please Try again`,
//         icon: "error",
//         confirmButtonColor: "#3085d6",
//         background: "#011225",
//         color: "white",
//       }).then(() => {
//         window.location.reload();
//       });
//     }
//   };
  
//   const handleDelete = async (id) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//       background: "#011225",
//       color: 'white',
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         const userToken = localStorage.getItem("aiUser");
//         try {
//           const response = await fetch(
//             `https://backend.whalesai.com/api/dashboard/user_signup-signin/delete-token/${id}`,
//             {
//               method: "DELETE",
//               headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${userToken}`,
//               },
//             }
//           );
  
//           if (!response.ok) {
//             const errorData = await response.json();
//             throw new Error(errorData.message || "Network response was not ok");
//           }
  
//           // Update tokensData state by filtering out the deleted token
//           setTokensData((prevTokens) =>
//             prevTokens.filter((item) => item._id !== id)
//           );
  
//           Swal.fire({
//             title: "Deleted!",
//             text: "Your token has been deleted.",
//             icon: "success",
//             confirmButtonColor: "#3085d6",
//             background: "#011225",
//             color: "white",
//           });
//         } catch (error) {
//           console.error("There was a problem with the fetch operation:", error);
//           Swal.fire({
//             title: "Error!",
//             text: `There was a problem deleting your token. \n Please Try again`,
//             icon: "error",
//             confirmButtonColor: "#3085d6",
//             background: "#011225",
//             color: "white",
//           }).then(() => {
//             window.location.reload();
//           });
//         }
//       }
//     });
//   };
  
//   const handleEdit = async (id, currentKeyName) => {
//     const { value: newKeyName } = await Swal.fire({
//       title: "Edit Token",
//       input: "text",
//       inputValue: currentKeyName,
//       inputLabel: "New Key Name",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Save",
//       background: "#011225",
//       inputValidator: (value) => {
//         if (!value || value.trim() === "") {
//           return 'Please enter a new key name';
//         }
//         return undefined;
//       },
//       color: 'white',
//     });
  
//     if (newKeyName) {
//       const userToken = localStorage.getItem("aiUser");
//       try {
//         const response = await fetch(
//           "https://backend.whalesai.com/api/dashboard/user_signup-signin/edit-token",
//           {
//             method: "PUT",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${userToken}`,
//             },
//             body: JSON.stringify({
//               newKeyName,
//               tokenId: id,
//             }),
//           }
//         );
  
//         if (!response.ok) {
//           const errorData = await response.json();
//           throw new Error(errorData.message || "Network response was not ok");
//         }
  
//         // Update tokensData state with the edited token
//         setTokensData((prevTokens) =>
//           prevTokens.map((item) =>
//             item._id === id ? { ...item, keyName: newKeyName } : item
//           )
//         );
  
//         Swal.fire({
//           title: "Success!",
//           text: "Token updated successfully.",
//           icon: "success",
//           confirmButtonColor: "#3085d6",
//           background: "#011225",
//           color: "white",
//         });
//       } catch (error) {
//         console.error("There was a problem with the fetch operation:", error);
//         Swal.fire({
//           title: "Error!",
//           text: `There was a problem updating your token.\n Please Try again`,
//           icon: "error",
//           confirmButtonColor: "#3085d6",
//           background: "#011225",
//           color: "white",
//         }).then(() => {
//           window.location.reload();
//         });
//       }
//     }
//   };
  
//   const handleCopy = (secretKey) => {
//     navigator.clipboard.writeText(secretKey).then(() => {
//       Swal.fire({
//         icon: 'success',
//         // title: 'Copied!',
//         text: 'The secret key has been copied to your clipboard.',
//         timer: 2000,
//         showConfirmButton: false,
//         background: '#011225',
//         color: 'white',
//       });
//     }).catch((error) => {
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: 'Something went wrong!',
//         footer: `<p>${error}</p>`,
//         background: '#011225',
//         color: 'white',
//       });
//     });
//   };
  


//   return (
//     <div>
//       <p className="fw-bold mt-4">API KEYS</p>

//       {/* <p>{token}</p> */}

//       <form className="api-key" onSubmit={handleSubmit}>
//         <input
//           type="text"
//           required
//           placeholder="ENTER NAME OF API"
//           className="py-2 px-2"
//           value={keyName}
//           onChange={handleInputChange}
//         />
//         <button type="submit" className="btn btn-info">
//           GENERATE........
//         </button>
//       </form>

//       {
//         tokensData.length !== 0 && <div className="table-responsive">
//         <table className="table">
//           <thead>
//             <tr className="table-headline">
//               <th>Name</th>
//               <th>Secret Key</th>
//               <th>Created Date</th>
//               <th>Action</th>
//             </tr>
//           </thead>
          
//           <tbody>
//             {tokensData.map((item) => (
//               <tr key={item._id}>
//                 <td>{item.keyName}</td>
//                 <td className="token-key">
//                   {item.secretKey.slice(0, 20)}....
//                   <FaCopy 
//                   className=""
//                   onClick={() => handleCopy(item.secretKey)} />
//                 </td>
//                 <td>{item.createdDate}</td>
//                 <td className="action-class">
//                   <FaEdit
//                     className="action-edit"
//                     onClick={() => handleEdit(item._id, item.keyName)}
//                   />
//                   <MdAutoDelete
//                     className="action-delete"
//                     onClick={() => handleDelete(item._id)}
//                   />
//                 </td>
//               </tr>
//             ))}
//           </tbody>

//         </table>
//       </div>
//       }

//     </div>
//   );
// };

// export default Token;























import React, { useContext, useEffect, useState } from "react";
import { FaCopy, FaEdit } from "react-icons/fa";
import { MdAutoDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { Pagination } from '@mui/material';
import './ApiSettings.css';

const Token = () => {
  const [token, setToken] = useState(null);
  const [keyName, setKeyName] = useState("");
  const [tokensData, setTokensData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const { AIuser } = useContext(UserAuthContext);

  const itemsPerPage = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const userToken = localStorage.getItem("aiUser");
    if (userToken) {
      setToken(userToken);
      console.log("Token set from localStorage:", userToken);
    } else {
      console.error("No token found in localStorage");
    }
  }, []);

  useEffect(() => {
    setTokensData(AIuser?.tokens || []);
  }, [AIuser]);

  useEffect( () => {
    const reversedTokensData = [...tokensData].reverse();
    setFinalData(reversedTokensData);
  } ,[tokensData])

  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = finalData?.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleInputChange = (event) => {
    setKeyName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const userToken = localStorage.getItem("aiUser");
    if (!userToken) {
      console.error("No token found");
      return;
    }
  
    const requestBody = {
      keyName: keyName,
    };
  
    try {
      const response = await fetch(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/generate-token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      console.log("Token generated successfully:", data);
      Swal.fire({
        title: "Success!",
        text: "Your token has been Generated Successfully.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        background: "#011225",
        color: "white",
      });
  
      // Update tokensData state with the new token
      setTokensData((prevTokens) => [...prevTokens, data]);
      setKeyName("");
    } catch (error) {
      console.log("There was a problem with the fetch operation:", error);
      Swal.fire({
        title: "Error!",
        text: `There was a problem Generating your token. \n\n Please Try again`,
        icon: "error",
        confirmButtonColor: "#3085d6",
        background: "#011225",
        color: "white",
      }).then(() => {
        window.location.reload();
      });
    }
  };
  
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      background: "#011225",
      color: 'white',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const userToken = localStorage.getItem("aiUser");
        try {
          const response = await fetch(
            `https://backend.whalesai.com/api/dashboard/user_signup-signin/delete-token/${id}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
  
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Network response was not ok");
          }
  
          // Update tokensData state by filtering out the deleted token
          setTokensData((prevTokens) =>
            prevTokens.filter((item) => item._id !== id)
          );
  
          Swal.fire({
            title: "Deleted!",
            text: "Your token has been deleted.",
            icon: "success",
            confirmButtonColor: "#3085d6",
            background: "#011225",
            color: "white",
          });
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
          Swal.fire({
            title: "Error!",
            text: `There was a problem deleting your token. \n Please Try again`,
            icon: "error",
            confirmButtonColor: "#3085d6",
            background: "#011225",
            color: "white",
          }).then(() => {
            window.location.reload();
          });
        }
      }
    });
  };
  
  const handleEdit = async (id, currentKeyName) => {
    const { value: newKeyName } = await Swal.fire({
      title: "Edit Token",
      input: "text",
      inputValue: currentKeyName,
      inputLabel: "New Key Name",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save",
      background: "#011225",
      inputValidator: (value) => {
        if (!value || value.trim() === "") {
          return 'Please enter a new key name';
        }
        return undefined;
      },
      color: 'white',
    });
  
    if (newKeyName) {
      const userToken = localStorage.getItem("aiUser");
      try {
        const response = await fetch(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/edit-token",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
            body: JSON.stringify({
              newKeyName,
              tokenId: id,
            }),
          }
        );
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Network response was not ok");
        }
  
        // Update tokensData state with the edited token
        setTokensData((prevTokens) =>
          prevTokens.map((item) =>
            item._id === id ? { ...item, keyName: newKeyName } : item
          )
        );
  
        Swal.fire({
          title: "Success!",
          text: "Token updated successfully.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          background: "#011225",
          color: "white",
        });
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
        Swal.fire({
          title: "Error!",
          text: `There was a problem updating your token.\n Please Try again`,
          icon: "error",
          confirmButtonColor: "#3085d6",
          background: "#011225",
          color: "white",
        }).then(() => {
          window.location.reload();
        });
      }
    }
  };
  
  const handleCopy = (secretKey) => {
    navigator.clipboard.writeText(secretKey).then(() => {
      Swal.fire({
        icon: 'success',
        // title: 'Copied!',
        text: 'The secret key has been copied to your clipboard.',
        timer: 2000,
        showConfirmButton: false,
        background: '#011225',
        color: 'white',
      });
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: `<p>${error}</p>`,
        background: '#011225',
        color: 'white',
      });
    });
  };
  
  return (
    <div>
      <p className="fw-bold mt-4">API KEYS</p>

      <form className="api-key" onSubmit={handleSubmit}>
        <input
          type="text"
          required
          placeholder="ENTER NAME OF API"
          className="py-2 px-2"
          value={keyName}
          onChange={handleInputChange}
        />
        <button type="submit" className="btn btn-info">
          GENERATE
        </button>
      </form>

      {
        tokensData.length !== 0 && 
        <div>
        <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="table-headline">
              <th>Name</th>
              <th>Secret Key</th>
              <th>Created Date</th>
              <th>Action</th>
            </tr>
          </thead>
          
          <tbody>

          {paginatedData.map((item) => (
              <tr key={item._id}>
                <td>{item.keyName}</td>
                <td className="token-key">
                  <p style={{width: '180px'}}>{item.secretKey.slice(0, 20)}....</p>
                  <FaCopy
                    className=""
                    onClick={() => handleCopy(item.secretKey)}
                  />
                </td>
                <td>{item.createdDate}</td>
                <td className="action-class">
                  <FaEdit
                    className="action-edit"
                    onClick={() => handleEdit(item._id, item.keyName)}
                  />
                  <MdAutoDelete
                    className="action-delete"
                    onClick={() => handleDelete(item._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
      </div>
      <Pagination
          count={Math.ceil(tokensData.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          className="pagination d-flex justify-content-center"
        />
      </div>
      }
    </div>
  );
};

export default Token;

