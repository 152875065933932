import React, { useEffect } from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';
import Banner from '../Home/Banner/Banner';
import MetaData from '../../Components/MetaData/MetaData';

const TermsOfUse = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaData
        pageTitle={
          "Best Generative AI Tool for Beginners | Easy Generative AI"
        }
        pageDescription={
          "Find the best generative AI tools for beginners to create text, images, audio, and more. Enjoy user-friendly platforms designed for easy learning and creativity."
        }
        canonical="https://whalesai.com/termsofuse"
      />
      <div>
        <PageHeading title={'Terms of Use'} />
        <div className="container account-container pb-5 about-us px-4 text-white">
          <p>Your access and use of this Website constitute your acceptance of and agreement to be bound by these Terms of Use. If you do not accept these Terms of Use, please discontinue your access, and do not use this Website.</p>

          <h3 className=''>General</h3>
          <ol>
            <li>These Terms of Use may be amended from time to time without prior or further notice to you. Your use of this Website after any amendment to these Terms of Use will constitute your acceptance of the amended Terms of Use.</li>
          </ol>

          <h3 className=''>Proprietary Rights</h3>
          <ol start="2">
            <li className='mb-3'>This Website is owned by Whales AI and is maintained by Whales AI.</li>
            <li>The materials, including but not limited to Whales AI’s content, text, compilations, graphics, images, software programs, and all other kinds of works, located on this Website (the “Contents”) are protected by copyright, trademark, and other forms of proprietary rights. All rights, title, and interest in the Contents are owned by, licensed to, or controlled by Whales AI.</li>
          </ol>

          <h3 className=''>Data Protection Notice</h3>
          <ol start="4">
            <li><a href="https://whalesai.com/dataprotectionnotice" target='_blank'>Click here to view this Website’s Data Protection Notice.</a></li>
          </ol>

          <h3 className=''>Restrictions on Use</h3>
          <ol start="5">
            <li className='mb-3'>Apart from any fair dealings permitted in law, the Contents shall not be reproduced, republished, uploaded, posted, transmitted, or otherwise distributed in any way, without the prior written permission of Whales AI.</li>
            <li className='mb-3'>Without limiting clause (5) of these Terms of Use, graphics and images on this Website shall not be reproduced or appropriated in any manner without the prior written permission of Whales AI.</li>
            <li>Modification of any of the Contents or use of any of the Contents for any unauthorized purpose will be a violation of Whales AI’s copyright and other intellectual property rights.</li>
          </ol>

          <h3 className=''>AI Content Disclaimer</h3>
          <ol start="8">
            <li className='mb-3'>This Website features content and services powered by Whales AI, the world's first AI-based social media marketing manager. While the AI provides innovative solutions for digital outreach, the results and content generated by the AI should not be solely relied upon without considering the context and human oversight.</li>
            <li>This Website is not a substitute for independent professional advice. Users should obtain appropriate professional advice tailored to their circumstances before relying on any AI-generated content.</li>
          </ol>

          <h3 className=''>Disclaimers of Warranties and Liability</h3>
          <ol start="10">
            <li>The Contents are provided on an “as is” basis without warranties of any kind. To the fullest extent permitted by law, Whales AI does not warrant and hereby disclaims any warranty:</li>
            <ul>
              <li className='mb-3'>a. as to the accuracy, correctness, completeness, reliability, timeliness, non-infringement, title, merchantability, or fitness for any particular purpose of the Contents.</li>
              <li className='mb-3'>b. that the Contents, or any functions of or available through this Website, will be error-free or available without delay or interruption, or that defects will be corrected, or that this Website and the server hosting this Website are and will remain free of all viruses and/or other harmful elements.</li>
              <li>c. Whales AI shall also not be liable for any damage, loss, or liability of any kind (whether actual, anticipated, consequential, special, economic, or otherwise) caused as a result (direct or indirect) of the use of, or inability to access or use, this Website, including but not limited to any damage, loss, or liability suffered as a result of reliance on the Contents.</li>
            </ul>
          </ol>
        </div>
      </div>
      <Banner />
    </div>
  );
};

export default TermsOfUse;
