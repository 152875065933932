import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './Login.css';
import swal from 'sweetalert';
import { Container, Row, Col, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import logo from '../../Assets/Images/ds-logo.png'
import './Forget.css';
import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Forgetpassword = () => {
  const navigate = useNavigate();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios.post("https://backend.whalesai.com/api/v1/admin/send-reset-password-link", { email: data.get('email') })
      .then(res => {
        if (res.status === 200) {
          swal({
            title: "Success",
            text: `${res.data.message}`,
            icon: "success",
            button: "OK!",
            className: "modal_class_success",
          });
        }
      })
      .catch(err => {
        swal({
          title: "Attention",
          text: `${err.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  return (
    <div className='handleTheLoginBody'>
      <Container className='pt-5 forCard p-5 rounded mx-auto'>
        <Row className='justify-content-center'>
          <Col xs={12} md={6}>
            <div className='text-center mb-4'>
              <img src={logo} className='handleLogoLogin' alt="logo" />
              <p style={{fontSize: '20px'}} className='mt-2 fw-bold'>Forgotten Password?</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="email"
                  name="email"
                  required
                />
              </Form.Group>
              

              <div className='d-flex justify-content-center gap-3'>
                <Button variant="primary" type="submit">
                  Retrieve Password
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => navigate("/admin/login")}
                >
                  Login
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Forgetpassword;
