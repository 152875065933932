import axios from "axios";
import { useContext, useEffect, useState } from "react";
import "./AdminDashboard.css";
import { Box, Card, CardContent, Grid } from "@mui/material";
import DashboardCard from "./DashboardCard";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
// import DashboardCard from "../../../components/DashboardCard";

const AdminDashboard = () => {
  const [students, setStudents] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [affiliate, setAffiliate] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [payments, setPayments] = useState();
  console.log(registrations, "REGISTRATIONS DATA");
  
  const {allAIAdmin, allAIUsers} = useContext(AdminAuthContext);
  console.log('from auth provider  ========>  ', allAIAdmin);


  // Total
  let arr = [];
  paymentData?.forEach((d) => {
    const amount = d?.amount;
    arr.push(Number(amount));
    console.log(amount);
  });

  let result = 0;
  for (let i = 0; i < arr.length; i++) {
    result += arr[i];
  }

  const exchangeRate = 0.7426;

  
  const usdAmount = result * exchangeRate;

  return (
    <div>
      <div className="container titlesregistered">
        <h5 className="text-white text-start mb-3 font-bold">DASHBOARD</h5>
        <div className="cards gap-4">
          <DashboardCard color="#A169E1" path="/admin/adminUser" count={allAIAdmin?.length ?? 0} title="ADMINS" />
          <DashboardCard color="#6C8A8F" path="/admin/users" count={allAIUsers?.length ?? 0} title="USERS" />
          {/* <DashboardCard color="#FF5B5B" path="/admin/payments" count={certificate?.length ?? 0} title="PAYMENTS" /> */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;