/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
import VideoAiVoices from './VideoAiVoices';
import { voices } from './api';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Popover, Spin } from 'antd';
import AddPageModal from './AddPageModal';
import html2canvas from 'html2canvas';
import PlayVideoModal from './PlayVideoModal';
import VideoEditModal from './VideoEditModal';
import swal from 'sweetalert';
import QuotesSlider from './QuotesSlider';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import PageHeading from '../../Components/PageHeading/PageHeading';
import VideoAiTab from './VideoAiTab';
import { formatTime } from '../../utils/formatTimer';
import { saveAs } from 'file-saver';
import { DownloadOutlined } from '@mui/icons-material';
import CreditsButton from '../../utils/CreditsButton';

const MakeVideo = () => {
  const { AIuser, initialTimer, userRefetch, setTokenLocal } =
    useContext(UserAuthContext);
  const location = useLocation();
  const [hasEdits, setHasEdits] = useState(false);

  const [defaultValue, setDefaultValue] = useState('');
  const [allImage, setAllImage] = useState([]);
  const [input, setInput] = useState('');
  const [pages, setPages] = useState([]);
  const [sizes, setSizes] = useState('0');
  const [openPlayModal, setOpenPlayModal] = useState(false);
  const [editVideo, setEditVideo] = useState(null);
  const [videoFormat, setVideoFormat] = useState(1);
  const [makingVideo, setMakingVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [newPageAdded, setNewPageAdded] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [voice, setVoice] = useState('');
  const [progress, setProgress] = useState(0);
  const origin = window.location.origin.toString();

  const [open, setOpen] = React.useState(false);

  const [videoUrl1, setVideoUrl1] = useState('');
  const [isVideoExpire, setisVideoExpire] = useState();
  console.log(videoUrl1, 'MONITORING VIDEO URL');
  const [videoUrl1Poster, setVideoUrl1Poster] = useState('');
  const [videoUrl16, setVideoUrl16] = useState('');
  const [videoUrl16Poster, setVideoUrl16Poster] = useState('');
  const [videoUrl9, setVideoUrl9] = useState('');
  const [videoUrl9Poster, setVideoUrl9Poster] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scroll(0,0);
  } ,[])

  useEffect(() => {
    const pageTexts = pages?.map((obj) => obj.text);
    localStorage.setItem('pageTexts', JSON.stringify(pageTexts));
    localStorage.setItem('pages', JSON.stringify(pages));
  }, [pages, tabValue, makingVideo]);

  // const [timer, setTimer] = useState(initialTimer);
  // console.log(timer, 'MAIN TIMER HERE')
  // const [isPaused, setIsPaused] = useState(false);
  // console.log(isPaused, 'IS TIMER PAUSED')

  // useEffect(() => {
  //   let interval;

  //   if (isPaused == true) {
  //     interval = setInterval(() => {
  //       setTimer((prevTimer) => prevTimer + 1);
  //     }, 1000);
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [isPaused]);

  // const handlePause = () => {
  //   setIsPaused(false);
  // };

  // const handleStart = () => {
  //   setIsPaused(true);
  // };

  const [timer1, setTimer1] = useState(0);
  const [timer16, setTimer16] = useState(0);
  const [timer9, setTimer9] = useState(0);

  const [isPaused1, setIsPaused1] = useState(false);
  const [isPaused16, setIsPaused16] = useState(false);
  const [isPaused9, setIsPaused9] = useState(false);

  // useEffects for each timer
  useEffect(() => {
    let interval;
    if (isPaused1) {
      interval = setInterval(() => {
        setTimer1((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isPaused1]);

  useEffect(() => {
    let interval;
    if (isPaused16) {
      interval = setInterval(() => {
        setTimer16((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isPaused16]);

  useEffect(() => {
    let interval;
    if (isPaused9) {
      interval = setInterval(() => {
        setTimer9((prevTimer) => prevTimer + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isPaused9]);

  const handleStart = (format) => {
    if (format == '0') setIsPaused1(true);
    else if (format == '1') setIsPaused16(true);
    else if (format == '2') setIsPaused9(true);
  };

  const handlePause = (format) => {
    if (format == '0') setIsPaused1(false);
    else if (format == '1') setIsPaused16(false);
    else if (format == '2') setIsPaused9(false);
  };

  const paragraphs = location?.state?.paragraphs;

  useEffect(() => {
    if (!location.state?.aiType) {
      if (newPageAdded == false) {
        const combinedArray = paragraphs?.map((el, index) => {
          return { ...el, image: allImage[index]?.imageName };
        });

        setPages(combinedArray);
      }
    }
  }, [paragraphs, allImage, newPageAdded, location.state?.aiType]);
  const imgFormat = location?.state?.format;
  console.log('imageeeeeeeeeeeeeeeeeeeeformattttttt =>>>>>>>.j :', imgFormat);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   const fetchData = async () => {
  //     const formatToEndpoint = ['1', '16', '9'];
  //     const format = formatToEndpoint[tabValue];

  //     let images;
  //     let sizes;

  //     if (format === '1') {
  //       console.log('Size 1 selected');
  //       images = await require.context(
  //         `/public/image/videoImg1`,
  //         false,
  //         /\.(png|jpe?g|svg)$/
  //       );
  //       console.log('imageeeeeeeeeeeeee: ', images);
  //       sizes = format;
  //     } else if (format === '16') {
  //       console.log('Size 16 selected');
  //       images = await require.context(
  //         `/public/image/videoImg16`,
  //         false,
  //         /\.(png|jpe?g|svg)$/
  //       );
  //       sizes = format;
  //     } else {
  //       console.log('Size 9 selected');
  //       images = await require.context(
  //         `/public/image/videoImg9`,
  //         false,
  //         /\.(png|jpe?g|svg)$/
  //       );
  //       sizes = format;
  //     }

  //     if (images) {
  //       const imageList = images
  //         .keys()
  //         .sort((a, b) => {
  //           const aNum = parseInt(a.match(/(\d+)/)[0]);
  //           const bNum = parseInt(b.match(/(\d+)/)[0]);
  //           return aNum - bNum;
  //         })
  //         .map((image) => {
  //           const imageName = /\/([^/]+)\.\w+$/.exec(image)[1];
  //           return { format: sizes, imageName: `/${imageName}.png` };
  //         });

  //       const dataLength = imageList?.length;

  //       const randomData = [];
  //       const selectedDataIndices = new Set();

  //       while (randomData.length < 50) {
  //         let randomIndex;
  //         do {
  //           randomIndex = Math.floor(Math.random() * dataLength);
  //         } while (selectedDataIndices.has(randomIndex));

  //         randomData.push(imageList[randomIndex]);
  //         selectedDataIndices.add(randomIndex);
  //       }

  //       setAllImage(randomData);
  //       setSizes(sizes);
  //     }
  //   };

  //   fetchData();
  // }, [tabValue]);

  const handleAddNewPage = () => {
    pages.push({
      text: input,
      id: pages?.length,
      customPage: true,
      textAlign: 'center',
      image: allImage[pages?.length]?.imageName,
    });

    setNewPageAdded(true);
    setHasEdits(true);
    setInput('');
    handleClose();
  };

  const handleRemovepage = (id) => {
    const newPages = pages.filter((page) => page.id !== id);
    setHasEdits(true);
    setPages(newPages);
  };

  const newPages = pages?.filter((page) => page.customPage === true);
  const newPagesLength = newPages?.length;
  const leftPages = newPagesLength > 0 ? 10 - newPagesLength : 10;

  const splittedVoice = voice?.split('-');
  localStorage.setItem('splittedVoice', JSON.stringify(splittedVoice));
  localStorage.setItem('voice', JSON.stringify(voice));

  const handleCapture = async (texts) => {
    try {
      setMakingVideo(true);

      const localValue = localStorage.getItem('tabValue');
      handleStart(localValue);

      const pageTexts = pages?.map((obj) => obj.text);
      const divs = document.querySelectorAll(
        ".capture-div[style*='background-image']"
      );

      console.log(
        'valueeeeeeeeeeeeeeeeee issssssssssssss fuck! ',
        localStorage.getItem('tabValue')
      );
      // Create an array to store all the blobs
      const blobs = [];

      // Loop through each div and capture it
      for (let i = 0; i < divs.length; i++) {
        const div = divs[i];

        // Use html2canvas to capture the div and convert it to an image
        const canvas = await html2canvas(div, {
          useCORS: true,
          allowTaint: true,
          scale: 2,
          backgroundColor: null,
        });

        // Convert the canvas to a Blob object
        const blob = await new Promise((resolve) =>
          canvas.toBlob(resolve, 'image/png')
        );

        // Add the Blob to the blobs array
        blobs.push(blob);
      }

      console.log(blobs);

      const localPageText =
        JSON.parse(localStorage.getItem('pageTexts')) || pageTexts || texts;
      const localSplittedVoice =
        JSON.parse(localStorage.getItem('splittedVoice')) || splittedVoice;
      const localVoice = JSON.parse(localStorage.getItem('voice')) || voice;

      const formData = new FormData();
      blobs.forEach((blob) => {
        formData.append('images', blob);
      });
      formData.append('sizes', localStorage.getItem('tabValue'));
      formData.append('pageTexts', JSON.stringify(localPageText));

      const response = await axios.post(
        `https://backend.dsl.sg/api/v1/ai-user/videoai-test?languageCode=${localSplittedVoice?.[0]}-${localSplittedVoice?.[1]}&voice=${localVoice}`,
        formData
      );
      if (response?.data?.data) {
        // setVideoUrl(response?.data?.data);
        const localValue = localStorage.getItem('tabValue');
        const localPagesJson = localStorage.getItem('pages');
        const localPages = JSON.parse(localPagesJson);
        handlePause(localValue);
        if (localValue == 0) {
          setVideoUrl1(response?.data?.data);
          setVideoUrl1Poster(response?.data?.thumbnail);
          // uniqeId

          await axios.post(
            'https://backend.whalesai.com/api/v1/credits-transaction/create',
            {
              aiType: 'Video Ai',
              transactionId: location?.state?.transactionId,
              videoUrl1Poster: response?.data?.thumbnail,
              videoUrl1: response?.data?.data,
              videoAiPages1: localPages,
              videoUrl16: videoUrl16,
              videoUrl16Poster: videoUrl16Poster,
              videoUrl9: videoUrl9,
              videoUrl9Poster: videoUrl9Poster,
              userId: AIuser?._id,
              videoAiDuration: timer1,
            }
          );
        } else if (localValue == 1) {
          setVideoUrl16(response?.data?.data);
          setVideoUrl16Poster(response?.data?.thumbnail);

          await axios.post(
            'https://backend.whalesai.com/api/v1/credits-transaction/create',
            {
              aiType: 'Video Ai',
              transactionId: location?.state?.transactionId,
              videoUrl16Poster: response?.data?.thumbnail,
              videoUrl16: response?.data?.data,
              videoAiPages16: localPages,
              videoUrl1: videoUrl1,
              videoUrl1Poster: videoUrl1Poster,
              userId: AIuser?._id,
              videoAiDuration: timer16,
              videoUrl9: videoUrl9,
              videoUrl9Poster: videoUrl9Poster,
            }
          );
        } else if (localValue == 2) {
          setVideoUrl9(response?.data?.data);
          setVideoUrl9Poster(response?.data?.thumbnail);

          await axios.post(
            'https://backend.whalesai.com/api/v1/credits-transaction/create',
            {
              aiType: 'Video Ai',
              transactionId: location?.state?.transactionId,
              videoUrl9Poster: response?.data?.thumbnail,
              videoUrl9: response?.data?.data,
              videoAiPages9: localPages,
              videoUrl16: videoUrl16,
              videoUrl16Poster: videoUrl16Poster,
              videoUrl1: videoUrl1,
              videoUrl1Poster: videoUrl1Poster,
              userId: AIuser?._id,
              videoAiDuration: timer9,
            }
          );
        }

        setProgress(100);
        setMakingVideo(false);

        setHasEdits(false); // Reset after successful capture
      } else {
        handleCapture();
      }
    } catch (error) {
      console.log('Captur error', error);

      handleCapture();
    }
  };

  // set random voice in useeffect
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * voices?.length);
    setVoice(voices?.[randomIndex]?.value);
    setDefaultValue(voices?.[randomIndex]);
  }, []);

  const onChangeVoices = (value) => {
    const selectedVoices = voices?.find((voice) => voice.value === value);

    setDefaultValue(selectedVoices);
    setVoice(value);
    setHasEdits(true); // Mark edits as true on page removal
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);

    console.log('valuseeeeeeeeeeeeeeeeeeeeeeeeeeeeeee : ', newValue);
    localStorage.setItem('tabValue', newValue);

    if (!location.state?.aiType) {
      if (newValue == 0 && !videoUrl1) {
        setMakingVideo(true);
        setTimeout(() => {
          handleCapture();
        }, 5000);
      } else if (newValue == 1 && !videoUrl16) {
        setMakingVideo(true);
        setTimeout(() => {
          handleCapture();
        }, 5000);
      } else if (newValue == 2 && !videoUrl9) {
        setMakingVideo(true);
        setTimeout(() => {
          handleCapture();
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (!location.state?.aiType) {
      const callCapture = async () => {
        if (pages) {
          localStorage.setItem('tabValue', 0);
          const pageTexts = pages?.map((obj) => obj.text);
          setMakingVideo(true);
          const localValue = localStorage.getItem('tabValue');

          handleStart(localValue);
          setTimeout(() => {
            handleCapture(pageTexts);
          }, 4000);
        }
      };
      callCapture();
    }
  }, [location.state?.aiType]);

  const downloadVideo2 = async (downUrl) => {
    const sanitizedText = pages[0].text
      .replace(/[^\w\s]/g, '')
      .split(' ')
      .join('-');

    setDownloading(true);
    const res = saveAs(downUrl, `${sanitizedText}.mp4`);

    setDownloading(false);
  };

  const [downloading, setDownloading] = useState(false);

  const generateRandomFilename = () => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let filename = '';
    for (let i = 0; i < 6; i++) {
      filename += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return filename;
  };

  const downloadVideo = async (videoUrl, dimension) => {
    try {
      setDownloading(true); // Set downloading state to true
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      const filename = generateRandomFilename();
      link.href = url;
      link.setAttribute('download', `${filename}-${dimension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading video:', error);
    } finally {
      setDownloading(false); // Reset downloading state to false
    }
  };

  useEffect(() => {
    if (location.state?.aiType == 'Video Ai') {
      const page1 = location.state.videoAiPages1;
      const page16 = location.state.videoAiPages16;
      const page9 = location.state.videoAiPages9;
      if (tabValue == 0) {
        setPages(page1);
        setTimer1(location.state.videoAiDuration);
      } else if (tabValue == 1) {
        setPages(page16);
        setTimer16(location.state.videoAiDuration);
      } else if (tabValue == 2) {
        setPages(page9);
        setTimer9(location.state.videoAiDuration);
      }
      // setPages(page1);
      setVideoUrl9Poster(location.state.videoAi?.videoUrl9Poster);
      setVideoUrl9(location.state.videoAi?.videoUrl9);
      setVideoUrl16Poster(location.state.videoAi?.videoUrl16Poster);
      setVideoUrl16(location.state.videoAi?.videoUrl16);
      setVideoUrl1Poster(location.state.videoAi?.videoUrl1Poster);
      setVideoUrl1(location.state.videoAi?.videoUrl1);
      setisVideoExpire(location.state.isVideoExpire)
      setMakingVideo(false);
      handlePause(tabValue);
    }
  }, [location.state, tabValue]);

  console.log('video url expire ========> : ', )

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <Fragment>
      <PageHeading title={'GENERATED VIDEO'} />
      <div className="img-ai-container container pb-5">
        <div style={{ maxWidth: '853px', marginInline: 'auto' }}>
          <div className="d-flex align-items-center justify-content-center centralize-credits-btn-sm">
            <div className="d-flex">
            {!isVideoExpire &&
              <CreditsButton />
            }
            </div>
          </div>

          <div>
            {/* <div className="d-flex justify-content-center mt-3">
            {tabValue === 0 && 
            <button className={`credits-button text-center text-uppercase mt-sm-0 mt-2 me-sm-2 me-0 test-class ${!isPaused1 && "buttonPaused"} ${isPaused1 && "animate-timer-button"}`}>
               <span></span>
               <span></span>
               <span></span>
               <span></span>
               {formatTime(timer1).hours}
               {": "}
               {formatTime(timer1).minutes}
               {": "}
               {formatTime(timer1).seconds}
            </button>}
            {tabValue === 1 && 
            <button className={`credits-button text-center text-uppercase mt-sm-0 mt-2 me-sm-2 me-0 test-class ${!isPaused16 && "buttonPaused"} ${isPaused16 && "animate-timer-button"}`}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {formatTime(timer16).hours}
              {": "}
              {formatTime(timer16).minutes}
              {": "}
              {formatTime(timer16).seconds}
            </button>}
            {tabValue === 2 && 
            <button className={`credits-button text-center text-uppercase mt-sm-0 mt-2 me-sm-2 me-0 test-class ${!isPaused9 && "buttonPaused"} ${isPaused9 && "animate-timer-button"}`}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {formatTime(timer9).hours}
              {": "}
              {formatTime(timer9).minutes}
              {": "}
              {formatTime(timer9).seconds}
            </button>}
          </div> */}
            {/* {timer > 0 && (
              <div className="d-flex justify-content-center mt-3">            
                <button className={`credits-button text-center text-uppercase mt-sm-0 mt-2 me-sm-2 me-0 test-class ${!isPaused && "buttonPaused"} ${isPaused && "animate-timer-button"}`}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  {formatTime(timer).hours}
                  {": "}
                  {formatTime(timer).minutes}
                  {":"}
                  {formatTime(timer).seconds}
                </button>
              </div>
            )} */}
            {/* <p
              style={{ fontSize: "13px" }}
              className="text-center pt-2 text-to-animate"
            >
              Please do not move away from this browser.
              <br className="videoai-br-hide" /> Processing will stop.
            </p> */}
          </div>
        </div>

        <div className="pt-3">
          <div style={{ maxWidth: '850px' }} className="mx-auto">
            <div className="d-flex align-items-center justify-content-center centralize-credits-btn-sm">
             {!isVideoExpire &&
              <VideoAiTab
              handleChangeTab={handleChangeTab}
              tabValue={tabValue}
              makingVideo={makingVideo}
              />
             } 
             
            </div>

            {makingVideo ? (
              <div className="my-3">
                {tabValue === 0 && (
                  <p
                    style={{ fontSize: '13px' }}
                    className="text-white text-center "
                  >
                    We are generating a 1:1 (1080x1080px) video for you.
                  </p>
                )}
                {tabValue === 1 && (
                  <p
                    style={{ fontSize: '13px' }}
                    className="text-white text-center "
                  >
                    We are generating a 16:9 (1920x1080px) video for you.
                  </p>
                )}
                {tabValue === 2 && (
                  <p
                    style={{ fontSize: '13px' }}
                    className="text-white text-center "
                  >
                    We are generating a 9:16  (1080x1920px) video for you.
                  </p>
                )}
                <QuotesSlider />
              </div>
            ) : (
              <>
              {!isVideoExpire?
                <div className="my-3 mx-auto d-flex align-items-center justify-content-center flex-column">
                {tabValue === 0 && (
                  <video
                    controls
                    playsInline
                    poster={videoUrl1Poster}
                    src={videoUrl1}
                    className="videoai-video-sm"
                    type="video/mp4"
                  />
                )}
                {tabValue === 1 && (
                  <video
                    controls
                    playsInline
                    poster={videoUrl16Poster}
                    src={videoUrl16}
                    className="videoai-video-sm2"
                    type="video/mp4"
                  />
                )}
                {tabValue === 2 && (
                  <video
                    controls
                    playsInline
                    poster={videoUrl9Poster}
                    src={videoUrl9}
                    className="videoai-video-sm3"
                    type="video/mp4"
                  />
                )}
                {tabValue == 0 && (
                  <>
                    <button
                      className="btn btn-success mt-2"
                      disabled={downloading}
                      onClick={() => downloadVideo(videoUrl1, '1-1.mp4')}
                    >
                      {downloading ? 'Downloading...' : 'Download'}
                    </button>
                    <p className="text-light text-center pt-4 mb-0">
                      1:1 - 1080 X 1080 px
                      <br />
                      (Instagram, Facebook, Twitter, LinkedIn and Pinterest)
                      </p>
                      <p className="text-warning text-center pt-4 mb-0">
                      Please download the video within 48 hours, as it will be deleted after that time.
                    </p>
                  </>
                )}

                {tabValue == 1 && (
                  <>
                  <button
                      className="btn btn-success mt-2"
                      disabled={downloading}
                      onClick={() => downloadVideo(videoUrl16, '16-9.mp4')}
                    >
                      {downloading ? 'Downloading...' : 'Download'}
                    </button>
                    <p className="text-light text-center pt-4 mb-0">
                      16:9 - 1920 x 1080 px
                      <br />
                      (YouTube, Instagram, Facebook, Twitter, LinkedIn and
                      Pinterest)
                    </p>
                    <p className="text-warning text-center pt-4 mb-0">
                      Please download the video within 48 hours, as it will be deleted after that time.
                    </p>
                  </>
                )}

                {tabValue == 2 && (
                  <>
                  <button
                      className="btn btn-success mt-2"
                      disabled={downloading}
                      onClick={() => downloadVideo(videoUrl9, '9-16.mp4')}
                    >
                      {downloading ? 'Downloading...' : 'Download'}
                    </button>
                    <p className="text-light text-center pt-4 mb-0">
                      9: 16 - 1080 x 1920 px
                      <br />
                      (Instagram, Facebook, Snapchat, TikTok, YouTube and
                      Pinterest)
                    </p>
                    <p className="text-warning text-center pt-4 mb-0">
                      Please download the video within 48 hours, as it will be deleted after that time.
                    </p>
                  </>
                )}
              </div>
              :
              <>
               <p className="text-warning text-center pt-4 mb-0">
               Your video has been deleted 48 hours after its creation.                
               </p>
              </>
              }
             </>
            )}

            {/* {tabValue===0 && <div
              className={`d-flex justify-content-between align-items-center makevideo-heading `}
              style={{ marginBottom: makingVideo && "20px" ,
                     display: (makingVideo && tabValue=='0')? "none" : "block" 
                    }}
            >
              {makingVideo && tabValue=='0' ? <></> :<VideoAiVoices
                onChangeVoices={onChangeVoices}
                defaultValue={defaultValue}
                voices={voices}
                
              />}
            </div>} */}

            <div>
              <div className="mt-1">
                {pages?.map((paragraph, index) => (
                  <div className="handle">
                    <div>
                      <p className=" text-start text-white mb-1">
                        Page {index + 1}
                        {!makingVideo && tabValue == '0' && (
                          <IconButton
                            aria-label="edit"
                            size="small"
                            sx={{
                              backgroundColor: 'rgba(190, 210, 180, 0.7)',
                              marginLeft: '4px',
                            }}
                            onClick={() => handleRemovepage(paragraph.id)}
                          >
                            <DeleteIcon
                              style={{ fontSize: '15px' }}
                              className="videoai-delete"
                            />
                          </IconButton>
                        )}
                      </p>
                    </div>

                    <div className="row px-2">
                      <div
                        className={`col-lg-8 col-md-7 col-sm-5 col-5 p-0 ${
                          location?.state?.format == 2 && 'makevideo-pagebox'
                        }`}
                      >
                        <p
                          className="mb-0 w-100 videodivBox text-white p-3 text-start position-relative me-2"
                          style={{
                            minHeight: '264px',
                            background: '#011C34',
                            borderRadius: '10px',
                            border: '1px solid #fff',
                            textTransform:
                              index == 0 &&
                              location?.state?.uniqeId !== 123 &&
                              'uppercase',
                            overflowWrap: 'anywhere',
                          }}
                          key={index}
                        >
                          {paragraph?.text}
                        </p>

                        <p
                          className={`text-white text-end`}
                          style={{
                            fontSize: '13px',
                            fontWeight: '500',
                            letterSpacing: '0.1px',
                          }}
                        >
                          {paragraph?.text?.length}/160 characters
                        </p>
                      </div>

                      <div
                        className={`col-lg-4 col-md-5 col-sm-7 col-7  ${
                          location?.state?.format == 2 && 'makevideo-imagebox'
                        } p-0 d-flex justify-content-start align-items-center `}
                        style={{ height: '264px' }}
                      >
                        {/* {allImage[index]?.format == "1" && ( */}
                        {tabValue == 0 && (
                          <Popover
                            content={
                              <div>
                                {makingVideo ? (
                                  <></>
                                ) : (
                                  <Button
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    className="bg-success"
                                    onClick={() => {
                                      setEditVideo({
                                        image: paragraph?.image,
                                        origin: `${origin}/image/videoImg1`,
                                        text: paragraph.text,
                                        pageNumber: index + 1,
                                        textAlign: pages[index].textAlign,
                                        width: 190,
                                        height: 190,
                                      });
                                      setVideoFormat({
                                        format: 1,
                                        pageCount: index,
                                      });
                                      setInput(null);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            }
                            trigger="click"
                          >
                            <div style={{ cursor: 'pointer' }}>
                              <div
                                className={`ms-2 d-flex justify-content-center align-items-${pages[index].textAlign} p-2 squareimg capture-div`}
                                style={{
                                  width: 230,
                                  height: 230,
                                  backgroundImage: `url("/image/videoImg1${paragraph?.image}")`,

                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              >
                                <div
                                  className="inside-box"
                                  style={{
                                    fontSize: '13px',
                                    backgroundColor: 'rgba(190, 210, 180, 0.7)',
                                    padding: '10px',
                                    textAlign: 'center',
                                    overflowWrap: 'anywhere',
                                  }}
                                >
                                  <p
                                    className="text-dark d-flex mb-0 "
                                    style={{
                                      fontWeight: '500',
                                      textTransform:
                                        index == 0 &&
                                        location?.state?.uniqeId !== 123 &&
                                        'uppercase',
                                    }}
                                  >
                                    {paragraph.text}
                                  </p>
                                </div>
                              </div>
                              {makingVideo ? (
                                <></>
                              ) : (
                                <p
                                  className="mb-0 text-light mt-2 text-center"
                                  style={{ fontSize: '12px' }}
                                >
                                  Click to Edit
                                </p>
                              )}
                            </div>
                          </Popover>
                        )}
                        {/* {allImage[index]?.format == "9" && ( */}
                        {tabValue == 2 && (
                          <Popover
                            content={
                              <div>
                                {makingVideo ? (
                                  <></>
                                ) : (
                                  <Button
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    className="bg-success"
                                    onClick={() => {
                                      setEditVideo({
                                        image: paragraph?.image,
                                        origin: `${origin}/image/videoImg9`,
                                        textAlign: pages[index].textAlign,
                                        text: paragraph.text,
                                        pageNumber: index + 1,
                                        width: 150,
                                        height: '237px',
                                      });
                                      setVideoFormat({
                                        format: 9,
                                        pageCount: index,
                                      });
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                              </div>
                            }
                            trigger="click"
                          >
                            <div style={{ cursor: 'pointer' }}>
                              <div
                                className={`ms-2 d-flex justify-content-center align-items-${pages[index].textAlign} p-2  capture-div `}
                                crossorigin="anonymous"
                                style={{
                                  width: 156,
                                  height: '278px',

                                  backgroundImage: `url("/image/videoImg9${paragraph?.image}")`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  backgroundRepeat: 'no-repeat',
                                }}
                              >
                                <div
                                  className="inside-box"
                                  style={{
                                    fontSize: '13px',
                                    backgroundColor: 'rgba(190, 210, 180, 0.7)',
                                    padding: '10px',
                                    textAlign: 'center',
                                  }}
                                >
                                  <p
                                    className="text-dark d-flex mb-0 "
                                    style={{
                                      fontWeight: '500',
                                      fontSize: '13px',
                                      textTransform:
                                        index == 0 &&
                                        location?.state?.uniqeId !== 123 &&
                                        'uppercase',
                                    }}
                                  >
                                    {paragraph.text}
                                  </p>
                                </div>
                              </div>
                              {/* {makingVideo ? <></> :
                              <p className="mb-0 text-light mt-2 text-center">
                                Click to Edit
                              </p>} */}
                            </div>
                          </Popover>
                        )}
                        {/* {allImage[index]?.format == "16" && ( */}
                        {tabValue == 1 && (
                          <>
                            <Popover
                              content={
                                <div>
                                  {makingVideo ? (
                                    <></>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      startIcon={<EditIcon />}
                                      className="bg-success"
                                      onClick={() => {
                                        setEditVideo({
                                          image: paragraph?.image,
                                          origin: `${origin}/image/videoImg16`,
                                          textAlign: pages[index].textAlign,
                                          text: paragraph.text,
                                          pageNumber: index + 1,
                                          width: '200px',
                                          height: 138,
                                        });
                                        setVideoFormat({
                                          format: 16,
                                          pageCount: index,
                                        });
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </div>
                              }
                              trigger="click"
                            >
                              <div style={{ cursor: 'pointer' }}>
                                <div
                                  className={`ms-2 d-flex justify-content-center align-items-${pages[index].textAlign} p-2  capture-div landscapeimg`}
                                  style={{
                                    width: '278px',
                                    height: '156px',

                                    backgroundImage: `url("/image/videoImg16${paragraph?.image}")`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                  }}
                                >
                                  <div
                                    className="inside-box"
                                    style={{
                                      fontSize: '13px',
                                      backgroundColor:
                                        'rgba(190, 210, 180, 0.7)',
                                      padding: '9px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <p
                                      className="text-dark d-flex mb-0 "
                                      style={{
                                        fontWeight: '500',
                                        textTransform:
                                          index == 0 &&
                                          location?.state?.uniqeId !== 123 &&
                                          'uppercase',
                                      }}
                                    >
                                      {paragraph.text}
                                    </p>
                                  </div>
                                </div>
                                {/* {makingVideo ? <></> :
                                <p className="mb-0  text-light mt-2 text-center">
                                  Click to Edit
                                </p>} */}
                              </div>
                            </Popover>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* </Draggable> */}
              </div>
            </div>
            {tabValue === 0 && (
              <>{makingVideo ? <></> : <div className="firstdivBtn"></div>}</>
            )}
          </div>
        </div>

        <AddPageModal
          handleAddNewPage={handleAddNewPage}
          input={input}
          setInput={setInput}
          open={open}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          pages={pages}
          leftPages={leftPages}
        />
        <PlayVideoModal
          openPlayModal={openPlayModal}
          setOpenPlayModal={setOpenPlayModal}
          videoUrl={videoUrl}
          makingVideo={makingVideo}
          progress={progress}
          format={location?.state?.format}
        />

        <VideoEditModal
          editVideo={editVideo}
          setEditVideo={setEditVideo}
          input={input}
          setInput={setInput}
          videoFormat={videoFormat}
          pages={pages}
          setPages={setPages}
          allImage={allImage}
          tabValue={tabValue}
          setHasEdits={setHasEdits}
        />
      </div>
    </Fragment>
  );
};

export default MakeVideo;
