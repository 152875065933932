
// Admin Private Route 


import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AdminAuthContext } from "../Contexts/AdminAuthContext";

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('AiAdmin');
    if (token) {
        return children;
    }
    return <Navigate to='/admin/login'/>;
};

export default PrivateRoute;