import React from "react";

const Spineer = () => {
  return (
    <div>
      <div class="container text-center my-4">
        <div class="row justify-content-center">
          <div class="col-auto">
            <div class="spinner-grow text-primary" role="status"></div>
          </div>
          <div class="col-auto">
            <div class="spinner-grow text-secondary" role="status"></div>
          </div>
          <div class="col-auto">
            <div class="spinner-grow text-success" role="status"></div>
          </div>
          <div class="col-auto">
            <div class="spinner-grow text-danger" role="status"></div>
          </div>
          <div class="col-auto">
            <div class="spinner-grow text-info" role="status"></div>
          </div>
          <div class="col-auto">
            <div class="spinner-grow text-warning" role="status"></div>
          </div>
          <div class="col-auto">
            <div class="spinner-grow text-dark" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spineer;
